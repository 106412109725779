import {useGetUserStatisticsQuery} from "@/api/user";
import {useCelebrationStore} from "@/components/elements/Celebration";
import {cn} from "@/lib/utils";
import {useEffect, useState} from "react";
// import {ChartConfig} from "@/components/ui/chart";

// const chartConfig = {
//     contacts: {
//         label: "Warming Contacts",
//         color: "hsl(var(--chart-1))",
//     },
//     warmContacts: {
//         label: "Warm Contacts",
//         color: "hsl(var(--chart-2))",
//     },
// } satisfies ChartConfig

const WarmingContacts = () => {
    const {data, isLoading} = useGetUserStatisticsQuery();

    const lastEntry = data?.[data.length - 1]

    const [blink, setBlink] = useState(false);

    // const chartData = [{
    //     contacts: (lastEntry?.totalContacts ?? 0) - (lastEntry?.totalWarmContacts ?? 0),
    //     warmContacts: lastEntry?.totalWarmContacts ?? 0,
    // }]

    const {celebrate} = useCelebrationStore((state) => state) as any;

    useEffect(()=>{
        const latestValueKey = 'warm_contacts_latest_value';
        const latestValue = localStorage.getItem(latestValueKey) ?? 0;

        if(lastEntry?.totalWarmContacts&&lastEntry?.totalWarmContacts > latestValue) {
            celebrate();

            setBlink(true);
            setTimeout(() => {
                setBlink(false);
            }, 10000);
        }

        if(lastEntry?.totalWarmContacts!==undefined&&lastEntry?.totalWarmContacts!==null) {
            localStorage.setItem(latestValueKey, lastEntry?.totalWarmContacts);
        }
    }, [celebrate, lastEntry?.totalWarmContacts]);

    if (isLoading) return <></>;

    return (
        <div className="w-full h-[200px] flex flex-col items-center justify-center gap-2">
            <div className="flex gap-2.5 items-end text-6xl font-display font-semibold">
                <p className={cn( {
                    'animate-pulse': blink
                })}>{lastEntry?.totalWarmContacts}</p>
                <p className="opacity-30">/ {lastEntry?.totalContacts}</p>
            </div>

            <p className="text-sm uppercase font-bold">Warm Contacts</p>
        </div>
    )

    // return (
    //     <div
    //         className="overflow-hidden w-full h-[220px]"
    //     >
    //         <ChartContainer
    //             config={chartConfig}
    //             className="mx-auto aspect-square w-full max-w-[250px]"
    //         >
    //             <RadialBarChart
    //                 data={chartData}
    //                 endAngle={180}
    //                 innerRadius={80}
    //                 outerRadius={130}
    //             >
    //                 <ChartTooltip
    //                     cursor={false}
    //                     content={<ChartTooltipContent hideLabel/>}
    //                 />
    //                 <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
    //                     <Label
    //                         content={({viewBox}) => {
    //                             if (viewBox && "cx" in viewBox && "cy" in viewBox) {
    //                                 return (
    //                                     <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
    //                                         <tspan
    //                                             x={viewBox.cx}
    //                                             y={(viewBox.cy || 0) - 16}
    //                                             className="fill-foreground text-2xl font-bold"
    //                                         >
    //                                             {lastEntry?.totalContacts}
    //                                         </tspan>
    //                                         <tspan
    //                                             x={viewBox.cx}
    //                                             y={(viewBox.cy || 0) + 4}
    //                                             className="fill-muted-foreground"
    //                                         >
    //                                             Contacts
    //                                         </tspan>
    //                                     </text>
    //                                 )
    //                             }
    //                         }}
    //                     />
    //                 </PolarRadiusAxis>
    //                 <RadialBar
    //                     dataKey="contacts"
    //                     stackId="a"
    //                     cornerRadius={0}
    //                     fill="hsl(var(--chart-1))"
    //                     className="stroke-transparent stroke-2"
    //                 />
    //                 <RadialBar
    //                     dataKey="warmContacts"
    //                     fill="hsl(var(--chart-2))"
    //                     stackId="a"
    //                     cornerRadius={0}
    //                     className="stroke-transparent stroke-2"
    //                 />
    //             </RadialBarChart>
    //         </ChartContainer>
    //     </div>
    // )
}

export default WarmingContacts;