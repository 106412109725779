import NavigationItem, {NavigationItemProps} from "@/components/layout/header/NavigationItem";
import {Bin, Play, Plus} from "iconoir-react";
import {useTranslation} from "react-i18next";
import useFullLists from "@/hooks/useFullLists";
import {Brain, Calendar, Edit, Gauge, MessageCircle, Pencil, Rss, Text, Users} from "lucide-react";
import ListEditPanel from "@/modules/contacts/components/ListEditPanel";
import {useCallback, useEffect, useRef, useState} from "react";
import {useCreateListMutation, useDeleteListMutation} from "@/api/list";
import {useUiAlert} from "@/components/elements/UiAlert";
import {List} from "@/api/list.types";
import {useNavigate} from "react-router";
import {usePatchContactMutation} from "@/api/contact";
import useFullContactsList from "@/hooks/useFullContactsList";
import {useGetUserRightsQuery} from "@/api/user";

const Navigation = () => {
    const {t} = useTranslation();

    const {content: lists} = useFullLists();

    const {data:userRights} = useGetUserRightsQuery();

    const [editingListUuid, setEditingListUuid] = useState<string | null>(null);

    const [newList] = useCreateListMutation()

    const [deleteList] = useDeleteListMutation()
    const listDeletionShouldDeleteContacts = useRef<boolean>(false);

    const [patchContact] = usePatchContactMutation()

    const {content:contacts} = useFullContactsList();
    const contactsCount = contacts?.length ?? 0;

    const {alert} = useUiAlert()

    const navigate = useNavigate();

    const handleListDelete = (list: List) => {
        const deleteContacts = listDeletionShouldDeleteContacts?.current ?? false;

        deleteList({
            uuid: list.uuid,
            deleteContacts
        })

        navigate('/')
    }

    const handleListDeleteAlert = useCallback((list: List) => {
        listDeletionShouldDeleteContacts.current = false;

        alert({
            title: t('common.delete') + ' ' + list.name,
            message: t('contacts.lists.delete_message'),
            options: [{
                name: 'deleteContacts',
                label: t('contacts.lists.delete_contacts'),
                description: t('contacts.lists.delete_contacts_description'),
                onChange: (checked: boolean) => {
                    listDeletionShouldDeleteContacts.current = checked
                }
            }],
            action: () => {
                handleListDelete(list)
            },
            cancellable: true
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigationItems: NavigationItemProps[] = [
        {
            label: t('common.navigation.dashboard'),
            link: '/dashboard',
            name: 'dashboard',
            icon: <Gauge/>,
            strict: true,
            shortcut: 'd',
            visible: userRights?.dashboard
        },
        {
            label: t('common.navigation.contacts'),
            link: '/contacts',
            name: 'contacts',
            icon: <Users/>,
            strict: true,
            children:contactsCount?( <span className="block text-xs text-muted-foreground/50 font-bold">
                {contactsCount+'/100'}
            </span>):undefined,
            visible: userRights?.contact_lists
        },
        {
            type: 'separator',
            label: t('common.navigation.lists'),
            name: 'lists',
            visible: userRights?.contact_lists
        },
        ...lists.map((list, index) => ({
            label: list?.name ?? '',
            link: "/lists/" + list?.uuid + "/contacts",
            name: 'list.' + list.uuid,
            onDrop: (contactUuid: string) => {
                patchContact({
                    uuid: contactUuid,
                    list_uuid: list.uuid
                })
            },
            objectIconProps: {
                item: list,
                model: 'list',
                name: 'icon'
            },
            actions: [
                {
                    label: t('common.edit'),
                    onClick: () => {
                        setEditingListUuid(list.uuid)
                    },
                    icon: <Edit/>
                }, {
                    label: t('common.delete'),
                    onClick: () => {
                        handleListDeleteAlert(list)
                    },
                    icon: <Bin/>
                }
            ],
            visible: userRights?.contact_lists,
            subItems: [
                {
                    icon: <Users/>,
                    label: t('common.navigation.contacts'),
                    link: '/lists/' + list?.uuid + '/contacts',
                    name: 'list.' + list.uuid + '.contacts',
                    visible: userRights?.contact_lists
                },
                {
                    icon: <Rss/>,
                    label: t('common.navigation.feed'),
                    link: '/lists/' + list?.uuid + '/feed',
                    name: 'list.' + list.uuid + '.feed',
                    visible: userRights?.feeds
                },
                {
                    icon: <Play/>,
                    label: t('common.navigation.pipelines'),
                    link: '/lists/' + list?.uuid + '/pipelines',
                    name: 'list.' + list.uuid + '.pipelines',
                    visible: userRights?.pipelines
                },
            ]
        })),
        {
            icon: <Plus/>,
            fixedIcon: true,
            label: t('contacts.lists.new'),
            onClick: () => {
                newList({
                    name: "My new list"
                }).then((response) => {
                    const uuid = response.data?.uuid;
                    if (uuid) setEditingListUuid(uuid)
                });
            },
            name: 'new_list',
            style: 'light',
            visible: userRights?.contact_lists
        },
        {
            icon: <Text/>,
            label: t('common.navigation.my_posts'),
            link: '/feed/posts',
            name: 'feed.posts',
            strict: true,
            visible: userRights?.posts,
            subItems: [
                {
                    icon: <Pencil/>,
                    label: t('common.navigation.write_post'),
                    link: '/feed/writer',
                    name: 'feed.write_post',
            visible: userRights?.posts
                },
                {
                    icon: <Calendar/>,
                    label: t('common.navigation.posts_queue'),
                    link: '/feed/queue',
                    name: 'feed.queue',
                    strict: true,
            visible: userRights?.posts
                }
            ]
        },
        {
            icon: <MessageCircle/>,
            label: t('common.navigation.messages'),
            link: '/messages',
            name: 'messages',
            visible: userRights?.messages
        },
        {
            icon: <Brain/>,
            label: t('common.navigation.resources'),
            link: '/resources',
            name: 'resources',
            visible: userRights?.resources
        },
        {
            icon: <Play/>,
            label: t('common.navigation.automation'),
            link: '/automation',
            name: 'automation',
            disabled: true,
            tooltip: t('common.coming_soon')?.toUpperCase(),
            visible: userRights?.automations
        }
    ]

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            for (const item of navigationItems) {
                if (event.ctrlKey || event.metaKey) {
                    if (item.shortcut && event.key === item.shortcut) {
                        event.preventDefault();

                        navigate(item.link ?? '/')
                        break;
                    }
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [navigate, navigationItems])

    return (
        <>
            <nav className="w-full pt-4">
                {navigationItems.map((item) => (
                    <NavigationItem
                        key={item.name}
                        {...item}
                    />
                ))}
            </nav>

            <ListEditPanel
                uuid={editingListUuid ?? ''}
                isOpen={!!editingListUuid}
                onClose={() => setEditingListUuid(null)}
            />
        </>
    );

}

export default Navigation;