import {useTranslation} from "react-i18next";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import {DialogDescription} from "@radix-ui/react-dialog";
import QueueSettings from "@/modules/feed/components/QueueSettings";
import {Button} from "@/components/ui/button";
import dayjs from "dayjs";
import {useUpdatePostMutation} from "@/api/writer";
import {clsx} from "clsx";
import {Link} from "react-router-dom";
import useQueueSchedule from "@/modules/feed/hooks/QueueSchedule";


const MyQueuePage = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
    } = useHeaderContainerStore((state) => state) as any;

    const [updatePost] = useUpdatePostMutation();

    const onDrop = (date: Date, slot: any, postUuid: string) => {
        const scheduledAt = dayjs(date)
            .set('hour', parseInt(slot.time.split(':')[0])).set('minute', parseInt(slot.time.split(':')[1]))
            .toDate();
        updatePost({
            uuid: postUuid,
            status: 'scheduled',
            scheduledAt
        })
    }

    useEffect(() => {
        setCrumbs([{
            label: t('feed.page_title'),
            to: "/feed"
        }, {
            label: t('feed.my_queue.page_title'),
            to: "/feed/queue"
        }]);
    }, [t, setCrumbs]);

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const  {
        nextDates
    } = useQueueSchedule();

    return (
        <>
            <Helmet>
                <title>{t('feed.my_posts.page_title')}</title>
            </Helmet>

            <div className="p-4">
                <div className="mb-8 flex justify-end items-center gap-2">
                    <Button
                        onClick={() => setIsSettingsOpen(true)}
                        variant="outline"
                    >
                        {t('feed.my_queue.settings_button')}
                    </Button>

                    <Button
                       asChild
                    >
                        <Link to="/feed/writer">
                            {t('feed.my_posts.write_post')}
                        </Link>
                    </Button>
                </div>

                <div className="space-y-8 max-w-[640px]">
                    {nextDates.map((date, index) => (
                            <div key={index}>
                                <p className="uppercase text-muted-foreground text-sm text-bold mb-4">{dayjs(date.date).format('dddd LL')}</p>

                                <div className="space-y-4">
                                    {date.slots.map((slot, index: number) => (
                                        <div key={index} className="flex gap-4">
                                            <p className={clsx("w-24 text-lg flex-shrink-0", {
                                                'italic': slot.temporary,
                                                'text-muted-foreground': slot.temporary,
                                            })}>
                                                {slot.time}
                                                {slot.temporary && <small className="block">
                                                    {t('feed.my_queue.temporary_slot')}
                                                </small>}
                                            </p>
                                            <div
                                                className="flex-grow rounded-lg bg-muted min-h-12 p-1 flex gap-2"

                                                onDragOver={(e) => {
                                                    e.preventDefault();
                                                    // setHasDragOver(true);
                                                }}
                                                onDragLeave={() => {
                                                    // setHasDragOver(false);
                                                }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // setHasDragOver(false);
                                                    onDrop(date.date, slot, e?.dataTransfer?.getData('text/plain'));
                                                }}
                                            >
                                                {slot.posts.map((post, index: number) => (
                                                    <div key={index}
                                                         className="p-2 line-clamp-4 bg-background rounded-md text-muted-foreground text-xs cursor-move"
                                                         draggable="true"
                                                         onDragStart={(e) => {
                                                             e.dataTransfer.setData('text/plain', post.uuid)
                                                         }}
                                                    >
                                                        {post.content}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>

            <Dialog
                open={isSettingsOpen}
                onOpenChange={setIsSettingsOpen}
            >
                <DialogContent
                    className="w-[720px] max-w-full"
                >
                    <DialogHeader>
                        <DialogTitle>
                            {t('feed.my_queue.settings_dialog_title')}
                        </DialogTitle>
                        <DialogDescription>
                            {t('feed.my_queue.settings_dialog_description')}
                        </DialogDescription>
                    </DialogHeader>

                    <QueueSettings/>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default MyQueuePage