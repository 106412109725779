import {cn} from "@/lib/utils";
import {useGetUserSubjectsQuery} from "@/api/dataset";
import {Button} from "@/components/ui/button";
import {Pen} from "lucide-react";
import {Link} from "react-router-dom";

const SubjectIdeas = () => {
    const {data, isLoading} = useGetUserSubjectsQuery();

    if (isLoading) {
        return <></>;
    }

    return (
        <div className="w-full">
            {data && data?.map((subject) => (
                <div
                    key={subject}
                    className={cn("flex items-center gap-2 py-1 border-b last:border-b-0 w-full")}
                >
                    <div className="flex-grow">
                        <p className="w-full">
                            {subject}
                        </p>
                    </div>

                    <div className="flex items-center gap-2">
                        <Button variant="link" asChild className="text-foreground">
                            <Link to={`/feed/writer?magicPostInit=${encodeURIComponent(subject)}`}>
                                <Pen size={16}/>
                            </Link>
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default SubjectIdeas;