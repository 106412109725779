import {Contact} from "@/api/contact.types";
import {useTranslation} from "react-i18next";
import {Button} from "@/components/ui/button";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";
import {NavArrowDown} from "iconoir-react";
import {useUiAlert} from "@/components/elements/UiAlert";
import {useDeleteContactMutation} from "@/api/contact";
import {useState} from "react";
import ContactEnrichDialog from "@/modules/contacts/components/ContactEnrichDialog";
import {useContactSheet} from "@/modules/contacts/components/ContactSheet";
import {useNavigate} from "react-router";

type ContactActionsProps = {
    contact: Contact
    variant?: 'default' | 'list-menu'
}
const ContactActions = ({contact, variant}: ContactActionsProps) => {
    const {t} = useTranslation()

    const [enrichOpen, setEnrichOpen] = useState(false)

    const {alert} = useUiAlert();

    const [deleteContact] = useDeleteContactMutation();

    const {isOpen: hasSheetOpen, setIsOpen: setSheetOpen} = useContactSheet((state) => state) as any;
    const navigate = useNavigate();

    const actions = [
        {
            label: t('contacts.actions.delete'),
            onClick: () => {
                alert({
                    title: t('contacts.delete_contact'),
                    message: t('contacts.delete_contact_message'),
                    action: () => {
                        deleteContact({uuid: contact.uuid})
                        if (hasSheetOpen) {
                            setSheetOpen(false)
                        } else {
                            navigate('/contacts')
                        }
                    },
                    cancellable: true
                })
            },
            order: 9999,
            disabled: false
        },
        // {
        //     label: t('contacts.actions.enrich'),
        //     order: 1,
        //     disabled: !contact.missing_info,
        //     onClick: () => setEnrichOpen(true)
        // },
        {
            label: t('contacts.actions.linkedin'),
            order: 2,
            onClick: () => {
                window.open('https://www.linkedin.com/in/' + contact.linkedin_id, '_blank')
            },
            disabled: !contact.linkedin_id
        }
    ].sort((a, b) => a.order - b.order);

    const firstAction = actions[0];

    if (variant === 'list-menu')
        return (<>
                <div
                    className="flex items-center"
                >
                    <div
                        onClick={firstAction.onClick ? (e: any) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (firstAction.disabled) {
                                return;
                            }

                            firstAction.onClick()
                        } : undefined}
                    >
                        <Button
                            variant="ghost"
                            className="bg-muted/50 p-3 font-normal rounded-full h-6 rounded-e-none hover:bg-muted"
                            disabled={firstAction.disabled}
                        >
                            {firstAction.label}
                        </Button>
                    </div>

                    <DropdownMenu>
                        <DropdownMenuTrigger
                            className="!outline-none hover:bg-muted/50 rounded-full p-1 -m-1 pr-4"
                            asChild
                        >
                            <Button
                                variant="ghost"
                                className="ml-[1px] bg-muted/50 p-2 font-normal rounded-full h-6 rounded-s-none hover:bg-muted"
                            >
                                <NavArrowDown className="h-4 w-4"/>
                            </Button>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent>
                            {actions?.map((action) => (
                                <div
                                    onClick={action.onClick ? (e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (action.disabled) {
                                            return;
                                        }

                                        action.onClick()
                                    } : undefined}
                                    key={action.label}
                                >
                                    <DropdownMenuItem
                                        disabled={action.disabled}
                                    >
                                        {action.label}
                                    </DropdownMenuItem>
                                </div>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>

                <ContactEnrichDialog
                    open={enrichOpen}
                    onOpenChange={setEnrichOpen}
                    contact={contact}
                />
            </>
        )

    return <div>TO DO</div>
}

export default ContactActions;