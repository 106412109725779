import {UserService} from "@/api/user.types";
import {useTranslation} from "react-i18next";
import {Button} from "@/components/ui/button";

type ServiceCardProps = {
    service: UserService
}

const ServiceCard = ({service}: ServiceCardProps) => {
    const {t} = useTranslation();

    return (
        <div className="flex items-center justify-between bg-muted p-4 rounded-md">
            <div>
                <h3 className="text-lg font-semibold">
                    {t('user.service_items.' + service.name)}
                </h3>
                <p>{service.enabled ? t('user.service_items.enabled') : t('user.service_items.disabled')}</p>
            </div>
            <Button asChild
                    variant={service.enabled ? 'link' : 'default'}
            >
                <a href={service.connectUrl}>
                    {t(service.enabled ? 'user.service_items.reconnect' : 'user.service_items.connect')}
                </a>
            </Button>

        </div>
    )
}

export default ServiceCard;