import {Plus} from "iconoir-react";
import {Contact} from "@/api/contact.types";
import {useRef} from "react";
import Logo from "@/components/elements/Logo";
import {cn} from "@/lib/utils";

type ScoreChipPropsT = {
    contact: Contact,
    variant?: 'rounded' | 'square'
}

const ScoreChip = ({contact, variant}: ScoreChipPropsT) => {
    const element = useRef<HTMLDivElement>(null);

    const score = contact?.score ?? 0;

    return (
        <div className="flex items-center" ref={element}>
            <span
                className={cn([
                    "font-normal h-6 w-6 flex items-center justify-center",
                    'bg-muted',
                    'text-sm'
                ], {
                    'rounded-full': variant?.includes('rounded'),
                    'ring-2':score >= 33,
                    'ring-tertiary':score >= 33,
                    'ring-primary':score >= 66,
                    'bg-primary':score >= 100,
                    'text-primary-foreground':score >= 100,
                })}
            >
                {score >= 100
                    ? <Logo className="h-auto w-[60%]" />
                    : score > 0
                        ? score
                        : <Plus className="h-5 w-auto opacity-50"/>
                }
            </span>
        </div>
    )
}

export default ScoreChip