import {PublicMedia} from "@/api/media.types";
import {Button} from "@/components/ui/button";
import React from "react";

type PublicMediaElementProps = {
    media: PublicMedia;
    onSelect?: (media: PublicMedia) => void;
}

const PublicMediaElement = ({media, onSelect}: PublicMediaElementProps) => {
    return (
        <div key={media.url} className="flex gap-4 items-center flex-col relative aspect-square group bg-muted">
            <img
                src={media.thumbnail}
                className="object-cover w-full h-full absolute top-0 left-0 z-10"
                alt={media.url}
            />

            <div
                className="absolute top-0 left-0 w-full h-full z-20 bg-black/50 text-white flex flex-col justify-between items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 ">
                <div className="line-clamp-2 p-4">&nbsp;</div>

                {onSelect &&
                    <Button onClick={() => onSelect?.(media)} className="bg-white text-black">
                        Select
                    </Button>}

                <div className="line-clamp-2 p-4">&nbsp;</div>
            </div>
        </div>
    )
}

export default PublicMediaElement;