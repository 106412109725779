import {Helmet} from "react-helmet";
import React, {useCallback, useEffect} from "react";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";
import PostFeed from "@/modules/feed/components/PostFeed";
import {useGetFeedItemQuery} from "@/api/feed";
import {useNavigate, useParams} from "react-router";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import {ScrollArea} from "@/components/ui/scroll-area";
import PostItem from "@/modules/feed/components/PostFeed/PostItem";
import PostItemSkeleton from "@/modules/feed/components/PostFeed/PostItemSkeleton";

const Feed = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('feed.page_title'));
    }, [t, setCrumbs, setTitle]);

    const {uuid} = useParams();
    const hasSinglePostItem = !!uuid;

    const {data: singlePostItem, isLoading: singlePostItemLoading} =
        useGetFeedItemQuery({
            uuid: uuid ?? ''
        }, {
            skip: !uuid
        });

    const navigate = useNavigate();

    const backToFeed = useCallback(() => navigate('/feed'), [navigate]);

    useEffect(() => {
        if (hasSinglePostItem && !singlePostItemLoading && !singlePostItem) {
            backToFeed();
        }
    }, [hasSinglePostItem, singlePostItemLoading, singlePostItem, backToFeed]);

    return (
        <div>
            <Helmet>
                <title>{t('feed.page_title')}</title>
            </Helmet>

            <div className="p-4 flex flex-col items-center justify-center bg-gray/50 min-h-screen">
                <PostFeed/>
            </div>

            {hasSinglePostItem &&
                <Dialog
                    open={hasSinglePostItem}
                    onOpenChange={(open) => {
                        if (!open) backToFeed()
                    }}
                >
                    <DialogContent className="sm:max-w-[620px]">
                        <DialogHeader>
                            <DialogTitle>
                                {t('feed.single_post.dialog_title')}
                            </DialogTitle>
                        </DialogHeader>

                        <ScrollArea
                            className="max-h-[86vh]"
                        >
                            {singlePostItemLoading ? (
                                <PostItemSkeleton/>
                            ) : singlePostItem ?
                                <PostItem post={singlePostItem}/> : null
                            }
                        </ScrollArea>
                    </DialogContent>
                </Dialog>
            }
        </div>
    )
}

export default Feed