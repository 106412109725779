import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";
import MediaLibrary from "@/modules/medias/components/MediaLibrary";

const Medias = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('medias.page_title'));
    }, [t, setCrumbs, setTitle]);

    return (
        <>
            <Helmet>
                <title>
                    {t('medias.page_title')}
                </title>
            </Helmet>

            <div
                className="flex h-full max-h-screen p-4 w-full"
            >
                <MediaLibrary />
            </div>
        </>

    )
}

export default Medias