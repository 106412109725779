import {Contact} from "@/api/contact.types";
import ScoreChip from "@/modules/contacts/components/ScoreChip";
import {Link} from "react-router-dom";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import {useContactSheet} from "@/modules/contacts/components/ContactSheet";

type StageCardProps = {
    contact: Contact
}

const StageCard = ({contact}: StageCardProps) => {
    const {setContactUuid} = useContactSheet((state) => state) as any;
    const handleCardClick = (e: any) => {
        e.preventDefault();
        setContactUuid(contact.uuid);
    }

    return (
        <Link className="block w-full mb-1 last:mb-0 p-4 bg-background backdrop-blur-sm rounded-sm"
              to={`/contacts/${contact.uuid}`}
              onClick={handleCardClick}
        >
            <div className="flex space-x-2 items-start">
                <ObjectAvatar
                    object={contact}
                />

                <div className="flex-grow">
                    <p className="font-bold">
                        {contact.display_name}
                    </p>
                    <p className="text-sm opacity-50">
                        {contact.company_position} @ {contact.company_name}
                    </p>
                </div>

                <ScoreChip
                    contact={contact}
                    variant="rounded"
                />
            </div>

            <div className="flex-1">

            </div>
        </Link>
    )
}

export default StageCard