import PostItem from "@/modules/feed/components/PostFeed/PostItem";
import {UserPost as UserPostType} from "@/api/writer.types";
import {useDeletePostMutation} from "@/api/writer";
import {FeedItem} from "@/api/feed.types";
import {Button} from "@/components/ui/button";
import {EditIcon, TrashIcon} from "lucide-react";
import {Link} from "react-router-dom";

type UserPostProps = {
    post: UserPostType
}

const UserPost = ({post}: UserPostProps) => {
     const [deletePost, {isLoading: deleteLoading}] = useDeletePostMutation();

    const handlePostDelete = () => {
        if (deleteLoading) return;

        deletePost({
            uuid: post.uuid
        })
    }

    return (
        <div className="max-w-[560px] w-full flex-shrink-0">
            <PostItem post={(post as FeedItem)}
                      withStatus={post.status}
                      withInteractions={false} withExternaLink={false}/>

            {post.isEditable && (
                <div
                    className="flex gap-2"
                >
                    <Button
                        disabled={deleteLoading}
                        onClick={handlePostDelete}
                        variant="outline"
                        className="text-current"
                    >
                        <TrashIcon/>
                    </Button>

                    <Button
                        disabled={deleteLoading}
                        asChild
                        variant="outline"
                        className="text-current"
                    >
                        <Link to={`/feed/writer/${post.uuid}`}>
                        <EditIcon/>
                        </Link>
                    </Button>
                </div>
            )}
        </div>
    )
}

export default UserPost