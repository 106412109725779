import {useGetCalendarEventsQuery, usePatchCalendarEventMutation} from "@/api/calendar_event";
import {Checkbox} from "@/components/ui/checkbox";
import DateRenderer from "@/components/elements/DateRenderer";
import {cn} from "@/lib/utils";
import ObjectAvatarsStack from "@/components/elements/ObjectAvatarsStack";
import dayjs from "dayjs";
import {useMemo} from "react";

const UserReminders = () => {
    const {data, isLoading} = useGetCalendarEventsQuery({
        page: 1,
        type: 'reminder',
        sortByKey: 'due_date',
        sortByDirection: 'desc',
    });

    const [patchCalendarEvent, {isLoading: updateLoading}] = usePatchCalendarEventMutation();

    const filteredData = useMemo(() => {
        if(!data) return null;
        return data.data?.filter(reminder => {
            return reminder.dueDate > dayjs().startOf('day').toDate() || !reminder.completed|| dayjs(reminder.updatedAt).isAfter(dayjs().startOf('day').toDate());
        });
    }, [data]);

    if (isLoading) {
        return <></>;
    }

    if (!filteredData?.length) {
        return <div className="h-[200px] flex items-center justify-center">
            <p className="text-center text-sm opacity-50">No reminders found</p>
        </div>
    }

    return (
        <div className="w-full">
            {filteredData && filteredData.map((reminder) => (
                <div
                    key={reminder.uuid}
                    className={cn("flex gap-2 py-2 border-b last:border-b-0 w-full")}
                >
                    <div className="pt-[1px]">
                        <Checkbox
                            className="border-foreground data-[state=checked]:bg-foreground data-[state=checked]:text-background rounded-[3px]"
                            checked={reminder.completed}
                            onCheckedChange={(completed) => {
                                if (updateLoading) return;

                                patchCalendarEvent({
                                    uuid: reminder.uuid,
                                    reminder: {
                                        completed: !!completed
                                    },
                                });
                            }}
                        />
                    </div>

                    <div className="flex-grow">
                        <p className={cn("w-full", {
                            'opacity-50': reminder.completed,
                            'line-through': reminder.completed,
                            'text-destructive': !reminder.completed && dayjs(reminder.dueDate).isBefore(dayjs(), 'day'),
                        })}>
                            {reminder.name}
                        </p>

                        {!reminder.completed && (
                            <p className="text-xs opacity-50">{reminder.description}</p>
                        )}
                    </div>

                    {!reminder.completed && (
                        <p className="text-xs opacity-50 flex-shrink-0 pt-1">
                            <DateRenderer date={reminder.dueDate}/>
                        </p>
                    )}

                    <ObjectAvatarsStack objects={reminder.relatedContacts ?? []}/>
                </div>
            ))}
        </div>
    )
}

export default UserReminders;