import {clsx} from "clsx";
import {Link} from "react-router-dom";
import {ArrowLeft, ChevronRight} from 'lucide-react';
import NotificationsPanel from "@/modules/user/components/NotificationsPanel";
import {create} from "zustand";
import React, {PropsWithChildren} from "react";
import {cn} from "@/lib/utils";

type Crumb = {
    label: string,
    to: string
}

export const useHeaderContainerStore = create((set) => ({
    title: '',
    crumbs: [],
    setTitle: (title: string) => set({title}),
    setCrumbs: (crumbs: Crumb[]) => set({crumbs})
}))

const HeaderContainer = ({children, isMain, className}: PropsWithChildren<{
    isMain?: boolean
    className?: string
}>) => {
    const {
        crumbs: storeCrumbs,
        title
    } = useHeaderContainerStore((state) => state) as any;

    let crumbs = storeCrumbs as Crumb[];

    const crumbsListClassnames = {
        'flex': true,
        'items-center': true,
        "last:pointer-events-none": (crumbs && crumbs.length > 1) || (title && (!crumbs || !crumbs.length)),
        'text-2xl': true,
        'font-bold': true,
        'font-display': true,
        'last:font-normal': (crumbs && crumbs.length > 1),
    }

    if (title && (!crumbs || !crumbs.length)) {
        crumbs = [{
            label: title,
            to: '/'
        }]
    }

    crumbs = crumbs?.filter(crumb => crumb.label.length);

    const showBackIcon = false;

    return (
        <div
            className={cn([
                "flex",
                "flex-shrink-0",
                "flex-grow-1",
                "items-center",
                "justify-between",
                "px-4",
                // "border-b",
                "w-full",
                "h-12",
                "sticky",
                "top-0",
                "bg-background/70",
                'backdrop-blur-md',
                "z-10",
                "gap-3"
            ], className)}
        >  {
                isMain && crumbs && <ul
                    className="flex items-center flex-grow"
                >
                    {showBackIcon && crumbs.length > 1 && <li className={clsx(crumbsListClassnames, ['mr-3'])}>
                        <Link to={crumbs[0].to}>
                            <ArrowLeft className="h-6 w-6"/>
                        </Link>
                    </li>}
                    {crumbs.map((crumb, index) => (
                        <li key={crumb.to}
                            className={clsx(crumbsListClassnames)}>
                            <Link
                                to={crumb.to}
                            >
                                {crumb.label}
                            </Link>

                            {index < (crumbs?.length ?? 1) - 1 && <ChevronRight className="h-6 w-6 opacity-50"/>}
                        </li>
                    ))}
                </ul>
            }

            {children}

            {isMain && <div
                className="flex items-center gap-4"
            >
                <NotificationsPanel/>
            </div>}
        </div>
    )
}

export default HeaderContainer;