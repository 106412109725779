import UserReminders from "@/modules/dashboard/components/widgets/UserReminders";
import WidgetCard, {WidgetCardProps} from "@/modules/dashboard/components/WidgetCard";
import {cn} from "@/lib/utils";
import {FC} from "react";
import TopTrendingContacts from "@/modules/dashboard/components/widgets/TopTrendingContacts";
import TopScoringContacts from "@/modules/dashboard/components/widgets/TopScoringContacts";
import SubjectIdeas from "@/modules/dashboard/components/widgets/SubjectIdeas";
import UserPostInteractions from "@/modules/dashboard/components/widgets/UserPostInteractions";
import WarmingContacts from "@/modules/dashboard/components/widgets/WarmingContacts";
import WarmingAdvices from "@/modules/dashboard/components/widgets/WarmingAdvices";
import {useGetUserRightsQuery} from "@/api/user";
import ComingSoon from "@/modules/dashboard/components/widgets/ComingSoon";

const DashboardWidgets = () => {
    const {data:userRights} = useGetUserRightsQuery();

    const widgets: (WidgetCardProps & {
        component: FC,
        name: string
    })[] = [
    //     {
    //     title: 'Pipeline Stages',
    //     component: PipelineCounts,
    //     size: 2,
    //     visible: userRights?.contact_lists
    // },
    //     {
    //     title: 'LinkedIn Profile',
    //     component: FollowersChart,
    //     size: 4,
    //     visible:true
    // },
        {
            name: 'warming_contacts',
        title: "Warming Contacts",
        component: WarmingContacts,
        size: 2,
        scheme: 'primary-light',
        visible: userRights?.contact_lists
    },
        {
            name: 'warming_advices',
        title: "Warming Advices",
        component: WarmingAdvices,
        size: 2,
        visible: userRights?.posts&&userRights?.comments
    },
        {
            name: 'posts_interactions',
        title: 'Posts Interactions',
        component: UserPostInteractions,
        size: 4,
        visible: userRights?.posts
    },

        {
            name: 'subject_ideas',
        title: 'Subject Ideas',
        component: SubjectIdeas,
        size: 2,
        visible: userRights?.posts
    },
        {
            name: 'top_trending_contacts',
        title: 'Top Trending Contacts',
        component: TopTrendingContacts,
        size: 2,
        visible: userRights?.contact_lists,
        scheme:'secondary'
    },
        {
            name: 'top_scoring_contacts',
        title: 'Top Scoring Contacts',
        component: TopScoringContacts,
        size: 2,
        visible: userRights?.contact_lists,
        scheme:'tertiary'
    },
    {
        name: 'reminders',
        title: 'Reminders',
        component: UserReminders,
        size: 4,
        visible: userRights?.contact_lists&& userRights?.calendar_events
    },
        {
            name: 'coming_soon',
            component: ComingSoon,
            size: 4,
            visible: true,
            scheme:'gray'
        },
    ]
    return (
        <div
            className={cn([
                'grid',
                'grid-cols-1',
                'md:grid-cols-2',
                'lg:grid-cols-4',
                'xl:grid-cols-6',
                'gap-2'
            ])}
        >
            {widgets
                .filter((widget) => widget.visible)
                .map((widget) => (
                <WidgetCard key={widget.name} title={widget.title} scheme={widget.scheme}
                            background={widget.background}
                            size={widget.size}>
                    <widget.component/>
                </WidgetCard>
            ))}
        </div>
    );
}

export default DashboardWidgets;