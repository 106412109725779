import {useGetListPipelineQuery} from "@/api/list";
import useFullLists from "@/hooks/useFullLists";
import {Contact} from "@/api/contact.types";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {usePatchContactMutation} from "@/api/contact";
import {CaretRightIcon} from "@radix-ui/react-icons";

type ContactListPipelineProps = {
    contact: Contact
}

const ContactListPipeline = ({contact}: ContactListPipelineProps) => {
    const list = contact?.list;
    const pipelineStage = contact?.pipeline_stages?.[0];

    const {content: lists} = useFullLists();

    const {data: pipeline} = useGetListPipelineQuery({
        uuid: list?.uuid ?? ''
    }, {
        skip: !list?.uuid
    });

    const [patchContact, {isLoading: isUpdating}] = usePatchContactMutation();

    return (
        <div className="flex items-center gap-4 w-full">
            <div className="flex flex-col flex-grow w-full">
                <Select
                    disabled={isUpdating}
                    value={list?.uuid}
                    onValueChange={(value) => {
                        patchContact({
                            uuid: contact.uuid,
                            list_uuid: value
                        })
                    }}
                    >
                    <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a list"/>
                    </SelectTrigger>
                    <SelectContent>
                        {lists?.map((list) => (
                            <SelectItem
                                key={list.uuid}
                                value={list.uuid}
                            >
                                {list.icon && <span className="mr-2">{list.icon}</span>}
                                {list.name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            {list&&
                <>
                    <CaretRightIcon className="size-6 opacity-50 flex-shrink-0" />
                    <div className="flex flex-col flex-grow w-full">
                        <Select
                            disabled={isUpdating}
                            value={pipelineStage?.uuid}
                            onValueChange={(value) => {
                                patchContact({
                                    uuid: contact.uuid,
                                    pipeline_stage_uuid: value
                                })
                            }}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select a stage"/>
                            </SelectTrigger>
                            <SelectContent>
                                {pipeline?.stages?.map((stage) => (
                                    <SelectItem
                                        key={stage.uuid}
                                        value={stage.uuid}
                                    >
                                        {stage.icon && <span className="mr-2">{stage.icon}</span>}
                                        {stage.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                </>
            }
        </div>
    )
}

export default ContactListPipeline;