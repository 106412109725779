import Notable from "@/components/elements/Notable/Notable";
import useFullContactsList from "@/hooks/useFullContactsList";
import {useGetContactFieldsQuery} from "@/api/dataset";
import {useContactSheet} from "@/modules/contacts/components/ContactSheet";
import useSocket from "@/hooks/useSocket";
import {useEffect, useState} from "react";
import {Contact} from "@/api/contact.types";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {Button} from "@/components/ui/button";
import useFullLists from "@/hooks/useFullLists";
import ObjectIcon from "@/components/data/ObjectIcon";
import {useDeleteContactMutation, usePatchContactMutation} from "@/api/contact";
import {useUiAlert} from "@/components/elements/UiAlert";
import {cn} from "@/lib/utils";

const ContactsTable = () => {

    const {content, isLoading: fieldsLoading, refetch} = useFullContactsList();

    const {data: fields, isLoading: contactsLoading} = useGetContactFieldsQuery();

    const {setContactUuid} = useContactSheet((state) => state) as any;

    const {lastEventData: lastContactUpdateEventData} = useSocket('contact_updates', true)
    const {lastEventData: lastContactInsertEventData} = useSocket('contact_inserts', true)

    useEffect(() => {
        refetch();
    }, [lastContactUpdateEventData, lastContactInsertEventData, refetch])

    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

    const {content:lists}= useFullLists();

    const [deleteContact] = useDeleteContactMutation();
    const [patchContact] = usePatchContactMutation();

    const [bulkActionLoading, setBulkActionLoading] = useState(false);
    const {alert} = useUiAlert();

    const handleBulkDelete = () => {
        alert({
            title: 'Delete contacts',
            message: 'Are you sure you want to delete the selected contacts?',
            action: async() => {
                setBulkActionLoading(true);
                for (const contact of selectedContacts) {
                    await deleteContact({uuid: contact.uuid});
                }
                setSelectedContacts([]);
                setBulkActionLoading(false);
            },
            cancellable: true
        })
    }

    const handleBulkListChange = async (listUuid: string) => {
        setBulkActionLoading(true);
        for (const contact of selectedContacts) {
            await patchContact({uuid: contact.uuid, list_uuid: listUuid});

        }
        setSelectedContacts([]);
        setBulkActionLoading(false);
    }

    return  <>

         <div className={cn("flex items-center justify-between gap-4 bg-gray dark:bg-muted pl-6 pr-2 py-2 rounded-full mb-4 w-full text-sm", {
             "bg-primary-300": selectedContacts.length > 0
         })}>
               <p>
               {selectedContacts.length > 0 ?  <>{selectedContacts.length} selected</> : <>&nbsp;</>}
            </p>

             <div>
                 <DropdownMenu>
                     <DropdownMenuTrigger
                     disabled={selectedContacts.length === 0 || bulkActionLoading} asChild
                     >
                     <Button variant="white">
                         Bulk actions
                     </Button>
                     </DropdownMenuTrigger>
                     <DropdownMenuContent>
                         <DropdownMenuLabel>{selectedContacts.length} contact{selectedContacts.length>1?'s':''}</DropdownMenuLabel>
                         <DropdownMenuSeparator />
                         <DropdownMenuSub>
                             <DropdownMenuSubTrigger>
                                 <span>Move to list</span>
                             </DropdownMenuSubTrigger>
                             <DropdownMenuPortal>
                                 <DropdownMenuSubContent>
                                     {lists?.map((list) => (
                                         <DropdownMenuItem
                                             key={list.uuid}
                                             onClick={() => handleBulkListChange(list.uuid)}
                                         >
                                             <ObjectIcon model="list" item={list} name="icon" disabled={true} className="mr-2"/>

                                             <span>{list.name}</span>
                                         </DropdownMenuItem>
                                     ))}
                                 </DropdownMenuSubContent>
                             </DropdownMenuPortal>
                         </DropdownMenuSub>
                         <DropdownMenuItem
                            onClick={() => handleBulkDelete()}
                         >
                             Delete {selectedContacts.length} contact{selectedContacts.length>1?'s':''}
                         </DropdownMenuItem>
                     </DropdownMenuContent>
                 </DropdownMenu>
             </div>
        </div>


    <Notable
        fields={fields ?? []}
        data={content ?? []}
        fieldsLoading={fieldsLoading}
        dataLoading={contactsLoading||bulkActionLoading}
        draggable={true}
        navigateTo={(uuid: string) => setContactUuid(uuid)}
        selectable={true}
        onSelect={setSelectedContacts}
    />

    </>
}

export default ContactsTable;