import {useGetUserStripePortalMutation, useGetUserSubscriptionsQuery} from "@/api/user";
import dayjs from "dayjs";
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@/components/ui/card";
import {Button} from "@/components/ui/button";

const UserAccount = () => {
    const {data:subscriptions, isLoading} = useGetUserSubscriptionsQuery();

    const activeSubscriptions = subscriptions?.filter((subscription) =>{
        return subscription.status === 'active' || subscription.status === 'trialing';
    });

    const [getPortalUrl] = useGetUserStripePortalMutation();

    if(isLoading) return null;

    return (
        <>
            <div className="space-y-4">
                {activeSubscriptions?.map((subscription) => (
                    <Card key={subscription.createdAt}>
                        <CardHeader>
                            <CardTitle className="text-xl rounded-sm">
                                <span className="capitalize">{subscription.status}</span> subscription
                            </CardTitle>
                            <CardDescription>Since {dayjs(subscription.createdAt).format('LL')}</CardDescription>
                        </CardHeader>
                        <CardContent>
                            {subscription.status==='trialing'&&<p className="mb-4 bg-primary-300 text-primary-foreground rounded-sm py-2 px-4 text-sm font-bold">
                                Your trial will end on {dayjs(subscription.trialEndsAt).format('LL')}.
                            </p>}

                            {(subscription.status==='trialing'||subscription.status==='active')&&
                            <div className="flex justify-end">
                                <Button onClick={(e)=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    getPortalUrl().then((response)=>{
                                        window.open(response?.data?.redirectUrl, '_self');
                                    });
                                }}>
                                    Manage subscription
                                </Button>
                            </div>
                            }
                        </CardContent>
                    </Card>
                ))}
            </div>
        </>
    )
}

export default UserAccount;