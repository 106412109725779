import {PropsWithChildren} from "react";
import {cn} from "@/lib/utils";
import {ScrollArea} from "@/components/ui/scroll-area";

export type WidgetCardProps = {
    scheme?: 'primary' | 'secondary' | 'primary-light' | 'tertiary' | 'success' | 'destructive' | 'warning' | 'gray' | 'light' | 'dark';
    title?: string;
    size?: number;
    background?: 'gradient-1' | 'gradient-2' | 'gradient-3';
    visible?:boolean;
}

const WidgetCard = ({children, scheme, title, size, background}: PropsWithChildren<WidgetCardProps>) => {
    return <div
        className={cn([
            'rounded-3xl',
            'p-4'
        ], {
            'bg-primary': (scheme === 'primary'),
            'bg-primary-300': (scheme === 'primary-light'),
            'bg-secondary': (scheme === 'secondary'),
            'bg-tertiary': (scheme === 'tertiary'),
            'bg-success': (scheme === 'success'),
            'bg-destructive': (scheme === 'destructive'),
            'bg-warning': (scheme === 'warning'),
            'bg-gray': (scheme === 'gray'),
            'bg-light': (scheme === 'light'),
            'bg-dark': (scheme === 'dark'),

            'text-black': (scheme === 'primary' || scheme === 'secondary' || scheme === 'success' || scheme === 'gray'),
            'text-white': (scheme === 'destructive' || scheme === 'warning' || scheme === 'light' || scheme === 'dark'),

            'border': !scheme,

            'relative': background?.includes('gradient'),
            'overflow-hidden': background?.includes('gradient'),
            'before:content': background?.includes('gradient'),
            'before:absolute': background?.includes('gradient'),
            'before:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]': background?.includes('gradient'),
            'before:from-chart-1/20': background?.includes('gradient'),
            'before:to-chart-1/0': background?.includes('gradient'),
            'before:via-chart-1/0': background?.includes('gradient'),
            'before:top-[12%]': background?.includes('gradient'),
            'before:left-[24%]': background?.includes('gradient'),
            'before:w-[120%]': background?.includes('gradient'),
            'before:aspect-square': background?.includes('gradient'),
            'before:-z-10': background?.includes('gradient'),
            'before:mix-blend-darken': background?.includes('gradient'),

            'before:top-[-100%]': background === 'gradient-2',
            'before:left-[-75%]': background === 'gradient-2',
            'before:w-[172%]': background === 'gradient-2',
            'before:opacity-50': background === 'gradient-2',

            'before:-top-16': background === 'gradient-3',
            'before:-right-16': background === 'gradient-3',
            'before:w-[180%]': background === 'gradient-3',
            'before:opacity-70': background === 'gradient-3',

            'after:content': background === 'gradient-1',
            'after:absolute': background === 'gradient-1',
            'after:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]': background === 'gradient-1',
            'after:from-chart-1/20': background === 'gradient-1',
            'after:to-chart-1/0': background === 'gradient-1',
            'after:via-chart-1/0': background === 'gradient-1',
            'after:top-[-90%]': background === 'gradient-1',
            'after:left-[-68%]': background === 'gradient-1',
            'after:w-[120%]': background === 'gradient-1',
            'after:aspect-square': background === 'gradient-1',
            'after:-z-10': background === 'gradient-1',
            'after:mix-blend-darken': background === 'gradient-1',

            'lg:col-span-2': size === 2,
            'lg:col-span-4': size === 4,
            'lg:col-span-6': size === 6,
        }, [
            'dark:!bg-muted dark:!text-muted-foreground',
        ])}
    >
        {title && <h1 className="text-lg font-semibold mb-2 font-display">{title}</h1>}
        <ScrollArea className="h-[224px]">
            {children}
        </ScrollArea>
    </div>
}

export default WidgetCard;