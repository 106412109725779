import {create} from "zustand";
import {useEffect} from "react";
import confetti from 'canvas-confetti';

export const useCelebrationStore = create((set) => ({
    celebrating: false,
    celebrate: () => set({celebrating: true}),
    stopCelebrating: () => set({celebrating: false}),
}))

const Celebration = () => {
    const {celebrating, stopCelebrating} = useCelebrationStore((state) => state) as any;

    useEffect(() => {
        if(celebrating ) {
            var count = 200;
            var defaults = {
                origin: { y: 0.7 }
            };

            const fire = (particleRatio:number, opts: {
                spread?: number,
                startVelocity?: number,
                decay?: number,
                scalar?: number,
                gravity?: number,
                inflection?: number,
            }) => {
                confetti({
                    ...defaults,
                    ...opts,
                    particleCount: Math.floor(count * particleRatio)
                });
            }

            fire(0.25, {
                spread: 26,
                startVelocity: 55,
            });
            fire(0.2, {
                spread: 60,
            });
            fire(0.35, {
                spread: 100,
                decay: 0.91,
                scalar: 0.8
            });
            fire(0.1, {
                spread: 120,
                startVelocity: 25,
                decay: 0.92,
                scalar: 1.2
            });
            fire(0.1, {
                spread: 120,
                startVelocity: 45,
            });
        }
    }, [celebrating, stopCelebrating]);

    return <></>
}

export default Celebration;