import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import React, {useEffect, useState} from "react";
import WysiwygEditor from "@/components/data/WysiwygEditor";
import LinkedInPostPreview from "@/modules/feed/components/LinkedInPostPreview";
import {TabsLight, TabsListLight, TabsTriggerLight} from "@/components/ui/tabs-light";
import {TabsContent} from "@/components/ui/tabs";
import WriterOptions from "@/modules/feed/components/WriterOptions";
import Loader from "@/components/elements/Loader";
import {Button} from "@/components/ui/button";
import {Media, PublicMedia} from "@/api/media.types";
import {useNewPostMutation, usePostQuery} from "@/api/writer";
import {useNavigate, useParams} from "react-router";
import useQueueSchedule from "@/modules/feed/hooks/QueueSchedule";
import dayjs from "dayjs";
import {Buffer} from 'buffer';
import ObjectMedias from "@/modules/medias/components/ObjectMedias";

const Writer = () => {
    const [postText, setPostText] = useState<string>('')
    const [contentIsLoading, setContentIsLoading] = useState(false)

    const {t} = useTranslation();

    const {uuid} = useParams();

    const {data:initialPost} = usePostQuery({uuid: uuid??''}, {
        skip: !uuid
    });

    useEffect(() => {
        if(initialPost){
            setContentIsLoading(true);
            setPostText(initialPost.content);
            setTimeout(() => {
                setContentIsLoading(false);
            }, 1000);
        }
    }, [initialPost]);

    const {
        setCrumbs,
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([{
            label: t('feed.page_title'),
            to: "/feed"
        }, {
            label: t('feed.writer.page_title'),
            to: "/feed/writer"
        }]);
    }, [t, setCrumbs]);

    const [medias, setMedias] = useState<(Media|PublicMedia)[]>([]);

    const [createNewPost] = useNewPostMutation();
    const navigate = useNavigate();

    const  {
        nextEmptyDate
    } = useQueueSchedule();

    const handleAddToQueue = async () => {
        createNewPost({
            scheduled_at: dayjs(nextEmptyDate).toDate(),
            post_text: postText,
            medias: await Promise.all(medias.map( async (item)=>{
                let url = item.url;
                if(!url.includes('base64')){
                    // Encode image to base64
                    const imageFormat = url.split('.').pop();

                    const buffer = await fetch(url).then(res => res.arrayBuffer());
                    const base64 = Buffer.from(buffer).toString('base64');
                    url = `data:image/${imageFormat};base64,${base64}`;
                }

                return {
                    type: 'image',
                    images: [{
                        url: url
                    }]
                }
            }))
        }).then((response) => {
            if(response.data?.status === 'scheduled') {
                setPostText('');
                setMedias([]);
                navigate('/feed/queue');
            } else {
                alert('Error adding to queue');
            }
        });
    }

    return (
        <div>
            <Helmet>
                <title>{t('feed.writer.page_title')}</title>
            </Helmet>

            <div className="flex">
                <div className="flex-grow flex flex-col ">
                    <div className="w-full flex-grow max-w-[720px] mx-auto">
                        {!contentIsLoading ?
                            <WysiwygEditor
                                value={postText}
                                onChange={setPostText}
                                placeholder={t('feed.writer.write_something')}
                                className="p-4 md:p-8 flex-grow overflow-y-auto flex flex-col [&>div]:h-full [&>div>div]:h-full [&>div>div>div]:h-full !h-full"
                            />
                        : <div className={"flex flex-col items-center justify-center h-full"}>
                            <Loader/>
                        </div>
                        }
                    </div>

                    <div className="p-4">
                        <div className="flex gap-2 justify-between items-end">
                            <ObjectMedias medias={medias} setMedias={setMedias} className="flex-grow" canAdd={true} canAddMultiple={false} addRelevancySearch={postText} />

                            {!initialPost &&
                                <Button onClick={handleAddToQueue} disabled={!postText || !nextEmptyDate} className="flex-shrink-0">
                                    Add to queue
                                </Button>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex-shrink-0 w-full max-w-[440px] bg-muted border-l min-h-[calc(100vh-3em)]">
                    <TabsLight defaultValue="writer">
                        <TabsListLight className="px-4">
                            <TabsTriggerLight value="writer">
                                {t('feed.writer.tabs.writer')}
                            </TabsTriggerLight>
                            <TabsTriggerLight value="preview">
                                {t('feed.writer.tabs.preview')}
                            </TabsTriggerLight>
                        </TabsListLight>

                        <TabsContent value="writer">
                            <div className="p-4">
                                <WriterOptions
                                    isSavingPost={false}
                                    onLoading={(loading) => {
                                        setContentIsLoading(loading)
                                    }}
                                    setPostText={(text) => {
                                        setContentIsLoading(true)
                                        setPostText(text)
                                        setTimeout(() => {
                                            setContentIsLoading(false)
                                        }, 100)
                                    }}
                                />
                            </div>
                        </TabsContent>

                        <TabsContent value="preview">
                            <div className="p-4">
                                <LinkedInPostPreview
                                    text={postText}
                                />
                            </div>
                        </TabsContent>
                    </TabsLight>
                </div>
            </div>
        </div>
    )
}

export default Writer