import {useDropzone} from 'react-dropzone'
import {useCallback} from "react";
import {cn} from '@/lib/utils';
import {useUploadMediaMutation} from "@/api/media";

type MediaUploaderProps = {
    onUploaded?: (media: any) => void
}
const MediaUploader = ({onUploaded}: MediaUploaderProps) => {
    const [uploadMedia, {isLoading}] = useUploadMediaMutation();

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.forEach((file:File) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.error('file reading has failed')
            reader.onload = () => {
                const fileType = file.type
                const fileName = file.name
                let result = reader.result as string;

                if (fileType.includes('image')) {
                    const image = new Image();
                    image.src = reader.result as string;
                    image.onload = () => {
                        const canvas = document.createElement('canvas');
                        let width = image.width;
                        let height = image.height;

                        // Resize logic
                        const maxSide = 2400;
                        if (width > height && width > maxSide) {
                            height *= maxSide / width;
                            width = maxSide;
                        } else if (height > maxSide) {
                            width *= maxSide / height;
                            height = maxSide;
                        }

                        canvas.width = width;
                        canvas.height = height;
                        const ctx = canvas.getContext('2d');
                        ctx?.drawImage(image, 0, 0, width, height);
                        result = canvas.toDataURL('image/jpeg', 1);
                    }
                }

                const base64=result as string

                uploadMedia({
                    name: fileName,
                    type: fileType.includes('image') ? 'image' : undefined,
                    base64,
                }).then((response) => {
                    if (onUploaded) {
                        onUploaded(response);
                    }
                })
            }
            reader.readAsDataURL(file)
        })

    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p
            className={cn([
                "border",
                "border-dashed",
                "border-2",
                "border-gray-400",
                "rounded-xl",
                "p-8",
                "w-full",
                "min-h-[80vh]",
                "text-center",
                "opacity-50",
                "cursor-pointer",
                "flex",
                "flex-col",
                "items-center",
                "justify-center",
            ], {
                "opacity-50": isLoading,
                "pointer-events-none": isLoading,
            })}
            >
                Drag 'n' drop some files here, or click to select files
            </p>
        </div>
    )
}

export default MediaUploader;