import {useGetUserSettingsQuery} from "@/api/user";
import {useMemo} from "react";
import {usePostsQuery} from "@/api/writer";
import {UserPost} from "@/api/writer.types";
import dayjs from "dayjs";

const useQueueSchedule = () => {
    const {data: userSettings, isLoading} = useGetUserSettingsQuery();

    const queueSettings = useMemo<any>(() => userSettings?.postsQueue ?? {}, [userSettings]);
    const queueDays = useMemo<string[]>(() => {
        const days: string[] = [];
        for (const slot of queueSettings?.slots ?? []) {
            for (const day of slot.days) {
                if (!days.includes(day)) {
                    days.push(day);
                }
            }
        }

        return days;
    }, [queueSettings]);

    const {data: scheduledPosts} = usePostsQuery({
        page: 1,
        status: "scheduled"
    });

    const {data: draftPosts} = usePostsQuery({
        page: 1,
        status: "draft"
    });

    const {data: publishedPosts} = usePostsQuery({
        page: 1,
        status: "published"
    });

    const allPosts = useMemo(() => {
        return [
            ...(scheduledPosts?.data ?? []),
            ...(draftPosts?.data ?? []),
            ...(publishedPosts?.data ?? [])
        ]
    }, [scheduledPosts, draftPosts, publishedPosts]);

    const nextDatesAmount = 150;

    const nextDates = useMemo(() => {
        const dates: {
            date: Date,
            slots: {
                time: string,
                posts: UserPost[],
                temporary?: boolean
            }[]
        }[] = [];
        const today = dayjs().startOf('day');
        for (let i = 0; i < nextDatesAmount; i++) {
            const checkDate = today.add(i, 'day');
            const checkDateDay = checkDate.format('dddd').toLowerCase();

            if (queueDays.includes(checkDateDay)) {
                const slots = (queueSettings.slots.filter((slot: any) => slot.days.includes(checkDateDay))
                    .map((slot: any) => {
                        const posts = allPosts.filter((post: any) => dayjs(post.scheduled_at) === checkDate).sort((a, b) => {
                            return dayjs(a.scheduled_at).diff(dayjs(b.scheduled_at));
                        });

                        const hours = slot.time.split(':')[0];
                        const minutes = slot.time.split(':')[1];

                        const formattedTime = dayjs().set('hour', hours).set('minute', minutes).format('HH:mm');

                        return {
                            time: formattedTime,
                            posts: posts
                        }
                    }));

                dates.push({
                    date: checkDate.toDate(),
                    slots
                });
            }
        }

        const postsFromNow = allPosts.filter((post: any) => dayjs(post.scheduled_at).isAfter(dayjs()));
        for (const post of postsFromNow) {
            const postDate = dayjs(post.scheduled_at);
            let inDates = dates.find((date) => dayjs(date.date).isSame(postDate, 'day'));
            if (!inDates) {
                dates.push({
                    date: postDate.toDate(),
                    slots: []
                });

                inDates = dates.find((date) => dayjs(date.date).isSame(postDate, 'day'));
            }
            const postTime = postDate.add(dayjs().utcOffset(), 'minute')
                .format('HH:mm');
            let inSlot = inDates?.slots.find((slot) => slot.time === postTime);
            if (!inSlot) {
                inDates?.slots.push({
                    time: postTime,
                    posts: [],
                    temporary: true
                });

                inSlot = inDates?.slots.find((slot) => slot.time === postTime);
            }
            let inPosts = inSlot?.posts.find((p) => p.uuid === post.uuid);
            if (!inPosts) {
                inSlot?.posts.push(post);
            }
        }

        return dates.sort((a, b) => {
            return dayjs(a.date).diff(dayjs(b.date));
        }).map((date) => {
            return {
                date: date.date,
                slots: date.slots.sort((a, b) => {
                    return dayjs(a.time, 'HH:mm').diff(dayjs(b.time, 'HH:mm'));
                })
            }
        });
    }, [allPosts, queueDays, queueSettings.slots]);

    const nextDatesExceptToday = nextDates.filter(date => !dayjs(date.date).isSame(dayjs(), 'day'));

    const nextEmptySlot = nextDatesExceptToday.find((date) => !date.slots.find((slot) => slot.posts.length));
    const nextEmptyDate = useMemo(() => {
        const date = nextEmptySlot?.date;
        const slot = nextEmptySlot?.slots?.[0];

        if(!date || !slot) return null;

        const returnDate = dayjs(date).add(parseInt(slot?.time.split(':')[0]), 'hour').add(parseInt(slot?.time.split(':')[1]), 'minute');
        return returnDate.toDate();
    }, [nextEmptySlot]);

    return {
        nextDates,
        nextEmptySlot,
        nextEmptyDate,
        isLoading
    }
}

export default useQueueSchedule;