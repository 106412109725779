import {Avatar, AvatarImage} from "@/components/ui/avatar";
import DateRenderer from "@/components/elements/DateRenderer";
import {useNavigate} from "react-router";
import {clsx} from "clsx";
import {NotificationAction} from "@/api/notification.types";
import {Button} from "@/components/ui/button";
import {cn} from "@/lib/utils";
import {usePatchUserNotificationMutation} from "@/api/notification";
import {useEffect, useRef} from "react";

type NotificationCardProps = {
    id: number;
    title: string,
    message: string,
    notifiedAt: string,
    image?: string;
    link?: string;
    type?: "default" | "progress";
    read?: boolean;
    actions?: NotificationAction[];
    markAsRead?: boolean;
}
const NotificationCard = ({id,read, type, title, link, image, message, notifiedAt, actions, markAsRead}: NotificationCardProps) => {
    const navigate = useNavigate();

    const [patchNotification] = usePatchUserNotificationMutation();

    const itemElement = useRef<HTMLDivElement>(null);
    const hoverTimeout = useRef<any>(null);

    useEffect(() => {
        if(markAsRead&&!read&&itemElement.current) {
            const onMouseHover = () => {
                if(hoverTimeout.current) {
                    clearTimeout(hoverTimeout.current);
                }
                hoverTimeout.current = setTimeout(() => {
                    patchNotification({
                        id: id,
                        read: true
                    }).then();
                }, 3000);
            }

            const onMouseLeave = () => {
                if(hoverTimeout.current) {
                    clearTimeout(hoverTimeout.current);
                }
            }

            itemElement.current.addEventListener('mouseenter', onMouseHover);
            itemElement.current.addEventListener('mouseleave', onMouseLeave);
            return () => {
                itemElement.current?.removeEventListener('mouseenter', onMouseHover);
                itemElement.current?.removeEventListener('mouseleave', onMouseLeave);
            }
        }
    }, [markAsRead,patchNotification,itemElement,id]);

    const handleClick = () => {
        if (link) {
            navigate(link);
        }

        const primaryAction = actions?.find(action => action.type === 'primary');
        if(primaryAction) {
            window.open(primaryAction.route, primaryAction.routeType === 'external' ? '_blank' : '_self');
        }

        if(markAsRead) {
            patchNotification({
                id: id,
                clicked: true,
                read: true
            }).then();
        }
    }

    return (
        <div
            ref={itemElement}
            onClick={handleClick}
            className={clsx("bg-background rounded-md gap-4 py-4 px-6 flex cursor-pointer transition-opacity", {
                "opacity-50": read,
                "hover:opacity-100": read
            })}>
            {image&&
            <Avatar>
                <AvatarImage src={image} alt={title}/>
            </Avatar>
            }
            <div
                className="flex flex-col w-full"
            >
                <div className="text-xs opacity-50">
                    <DateRenderer date={notifiedAt}/>
                </div>

                <div className={"flex gap-2 items-center"}>
                    {type === 'progress' &&
                        <div className="bg-primary size-2 rounded-full animate-pulse"/>
                    }

                    <div className="text-lg font-semibold font-display">{title}</div>
                </div>

                <div className="text-sm mt-2">{message}</div>

                {actions && actions.length > 0 &&
                    <div className="mt-4 flex gap-4 items-center justify-end w-full border-t border-tertiary pt-4" onClick={(e: any) => {
                        e.stopPropagation();

                        patchNotification({
                            id: id,
                            read: true
                        }).then()
                    }}>
                        {actions.map((action, index) => (
                            <Button
                                key={index}
                                variant={action.type === 'primary' ? 'tertiary' : 'ghost'}
                                asChild
                                className={cn("text-xs", {
                                    'text-tertiary-foreground dark:text-muted-foreground': action.type !== 'primary',
                                })}
                            >
                                <a
                                    href={action.route}
                                    target={action.routeType === 'external' ? '_blank' : '_self'}
                                    rel="noreferrer"
                                >
                                    {action.label}
                                </a>
                            </Button>
                        ))}
                    </div>}
            </div>
        </div>
    )
}

export default NotificationCard;