import {useGetUserAvailablePlansQuery} from "@/api/user";
import {Switch} from "@/components/ui/switch";
import {useState} from "react";
import PlanCard from "@/modules/subscription/PlanCard";
import {UserAvailablePlan} from "@/api/user.types";

const SubscriptionPlans = () => {
    const {data, isLoading} = useGetUserAvailablePlansQuery();
    const [recurringPlanType, setRecurringPlanType] = useState<'month' | 'year'>('month');

    const fakePremiumPlan: UserAvailablePlan = {
        internal_product_id: 'premium',
        name: 'Premium',
        description: 'Get access to all premium features',
        active: false,
        soon:true,
        features: [
            'Sync your LinkedIn data',
            'Build contact lists',
            'Score interactions',
            'Create automations',
            'Create pipelines',
            'Unified inbox',
            'AI writing assistant'
            ],
        prices: [
            {
                active: true,
                currency: 'EUR',
                interval: 'month',
                interval_count: 1,
                product: 'premium',
                unit_amount: 1000,
                default_price: 1000
            }, {
                active: true,
                currency: 'EUR',
                interval: 'year',
                interval_count: 1,
                product: 'premium',
                unit_amount: 10000,
                default_price: 10000
            }
        ]
    }

    return (
        <div
            className="w-full flex flex-col gap-4 align-center justify-center"
        >

            <label className="flex items-center gap-4 w-full justify-center uppercase text-sm font-medium">
                <p>Billed monthly</p>
                <Switch
                    className="!bg-foreground data-[state=checked]:bg-foreground"
                    checked={recurringPlanType === 'year'}
                    onCheckedChange={(checked) => {
                        setRecurringPlanType(checked ? 'year' : 'month')
                    }}
                />
                <p>Billed yearly</p>
            </label>

            <div className="flex items-center gap-4 w-full justify-center max-md:flex-col">
                {isLoading && <PlanCard
                    loading={true}
                    recurringPlanType={recurringPlanType}
                />}
                {!isLoading&&data?.map((plan) => (
                    <PlanCard
                        key={plan.internal_product_id}
                        plan={plan}
                        recurringPlanType={recurringPlanType}
                    />
                ))}

                <PlanCard loading={isLoading} plan={fakePremiumPlan} recurringPlanType={recurringPlanType}/>
            </div>
        </div>
    )
}

export default SubscriptionPlans;