import {Contact} from "@/api/contact.types";
import {useGetFeedQuery} from "@/api/feed";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FeedItem} from "@/api/feed.types";
import PostItem from "@/modules/feed/components/PostFeed/PostItem";
import PostItemSkeleton from "@/modules/feed/components/PostFeed/PostItemSkeleton";
import {useTranslation} from "react-i18next";
import useFullLists from "@/hooks/useFullLists";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {useParams} from "react-router";
import {Button} from "@/components/ui/button";
import {ExternalLink} from "lucide-react";

type PostFeedProps = {
    contact?: Contact
}
const PostFeed = ({contact}: PostFeedProps) => {
    const bottomRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<FeedItem[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [selectedList, setSelectedList] = useState<string | null>(null);

    const {listUuid: listUuidParam} = useParams();

    const {isLoading, data: currentPageData, refetch} = useGetFeedQuery({
        contact_uuid: contact?.uuid ?? '',
        list_uuid: listUuidParam ?? (!selectedList || selectedList === 'all' ? '' : selectedList),
        page
    });

    const {content: lists} = useFullLists();

    const {t} = useTranslation();

    useEffect(() => {
        setData([])
        setPage(1)
        setHasMore(true)
    }, [contact, selectedList, listUuidParam]);

    useEffect(() => {
        if (currentPageData) {
            setData(prev => [...prev, ...currentPageData.data.filter(item => {
                return !prev.find(i => i.uuid === item.uuid)
            })])
            if (currentPageData.meta.last_page === currentPageData.meta.current_page) {
                setHasMore(false)
                if (page === 1) setTimeout(() => {
                    refetch()
                }, 5000);
            }
        }
    }, [currentPageData, page, refetch])

    const loadNextPage = useCallback(() => {
        if (currentPageData && hasMore) {
            setPage(page + 1)
            setPage(currentPageData.meta.current_page + 1)
            setHasMore(currentPageData.meta.last_page > currentPageData.meta.current_page)
        }
    }, [currentPageData, hasMore, page])

    const onScroll = useCallback(() => {
        if (bottomRef.current) {
            if (bottomRef.current.getBoundingClientRect().bottom <= (window.innerHeight * 5)) {
                loadNextPage()
            }
        }
    }, [loadNextPage])

    const closestScrollableParent = useMemo(() => {
        const element = bottomRef.current;
        if (!element) return null;

        let parent = element.parentElement;
        while (parent) {
            if (parent.scrollHeight > parent.clientHeight) {
                return parent;
            }
            parent = parent.parentElement;
        }
        return null;
    }, [data]);

    useEffect(() => {
        if (closestScrollableParent)
            closestScrollableParent.addEventListener('scroll', onScroll)

        window.addEventListener('scroll', onScroll)

        return () => {
            if (closestScrollableParent)
                closestScrollableParent.removeEventListener('scroll', onScroll)

            window.removeEventListener('scroll', onScroll)
        }
    }, [closestScrollableParent, onScroll])

    return (
        <div>
            <div
                className="flex items-center justify-between mb-4 gap-4 w-[560px]"
            >
                {!listUuidParam && !contact && lists.length > 1 && (
                    <div
                        className={"w-48"}
                    >
                        <Select
                            disabled={isLoading}
                            value={selectedList ?? ''}
                            onValueChange={setSelectedList}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder={t('feed.list_filter')}/>
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value={'all'}>
                                    {t('feed.list_filter')}
                                </SelectItem>
                                {lists.map((list) => (
                                    <SelectItem
                                        key={list.uuid}
                                        value={list.uuid}
                                    >
                                        {list.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                )}

                <div/>
            </div>

            {data?.map(post => (
                <PostItem key={post.uuid} post={post}/>
            ))}
            <div ref={bottomRef}
            >
                {hasMore && <div>
                    <PostItemSkeleton/>
                    <PostItemSkeleton/>
                    <PostItemSkeleton/>
                </div>
                }
            </div>


            {(!data.length && !isLoading) &&
                (<div className="text-center text-muted py-16">{t('feed.empty')}</div>)
            }



            {listUuidParam&&lists?.find((list) => list.uuid===listUuidParam)?.linkedin_feed_url && <div className="fixed bottom-4 right-4">
               <Button asChild>
                <a href={lists?.find((list) => list.uuid===listUuidParam)?.linkedin_feed_url} target="_blank" rel="noreferrer">
                    Open feed in LinkedIn <ExternalLink className="ml-2 size-4"/>
                </a>
               </Button>
            </div>}

        </div>
    )
}

export default PostFeed;