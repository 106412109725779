import {useDeleteMediaMutation, useGetMediasQuery, usePublicSearchQuery} from "@/api/media";
import MediaUploader from "./MediaUploader";
import React, {useEffect, useMemo, useState} from "react";
import {Button} from "@/components/ui/button";
import {ScrollArea} from "@/components/ui/scroll-area";
import Loader from "@/components/elements/Loader";
import MediaElement from "@/modules/medias/components/MediaElement";
import {Media, PublicMedia} from "@/api/media.types";
import {Input} from "@/components/ui/input";
import {LayoutGrid, Upload} from "lucide-react";
import {useDebounce} from "@uidotdev/usehooks";
import PublicMediaElement from "@/modules/medias/components/PublicMediaElement";

type MediaLibraryProps = {
    multiple?: boolean;
    onSelect?: (media: any) => void;
    onClose?: () => void;
    relevancySearch?: string;
}

const MediaLibrary = ({multiple, onSelect,onClose,relevancySearch}: MediaLibraryProps) => {
    const {data, isLoading} = useGetMediasQuery({
        page: 1,
    });

    const [searchValue, setSearchValue] = useState<string>('');

    const searchValueDebounced = useDebounce(searchValue, 500);

    const [deleteMedia] = useDeleteMediaMutation();

    const [panel, setPanel] =useState('list');

    const [selectedMedia, setSelectedMedia] = useState<(Media|PublicMedia)[]>([]);

    const {data:publicMedias} = usePublicSearchQuery({
        query: searchValueDebounced??''
    }, {
        skip: !searchValueDebounced
    });

    const [relevantMedias, setRelevantMedias] = useState<(Media)[]>([]);

    useEffect(() => {
        if (!isLoading&&!data?.data?.length) setPanel('upload');
    }, [data, isLoading]);

    useEffect(() => {
        if(!isLoading&&data?.data?.length&&relevancySearch){
            const relevancySearchWords = relevancySearch.split(' ').filter(word => word.length > 3);
            const medias = data?.data.filter(media => relevancySearchWords.some(word => media.description?.toLowerCase().includes(word)));

            // Sort by common words count desc
            const mostRelevantMedia = medias.sort((a, b) => {
                let aWordsCount = 0;
                let bWordsCount = 0;
                for (const word of relevancySearchWords) {
                    aWordsCount += a.description?.toLowerCase().split(word).length ?? 0;
                    bWordsCount += b.description?.toLowerCase().split(word).length ?? 0;
                }
                return bWordsCount - aWordsCount;
            }).slice(0, 5);

            setRelevantMedias(mostRelevantMedia);
        }
    }, [data, isLoading, relevancySearch]);

    const handleDelete = (media: any) => {
        deleteMedia({uuid: media.uuid})
    }

    const handleSelect = (media: any) => {
        if(!media?.uuid) return;
        
        if(selectedMedia.includes(media)){
            // Remove media from selectedMedia
            setSelectedMedia(selectedMedia.filter(m => (m as any).uuid !== media.uuid));
        } else {
            // Add media to selectedMedia
            setSelectedMedia([...selectedMedia, media]);
        }

        if(onSelect){
            onSelect(media);
        }

        if(!multiple){
            onClose?.();
        }
    }
    
    const handlePublicSelect = (object: PublicMedia) => {
        if(!object?.url) return;
        
        if(selectedMedia.includes(object)){
            // Remove media from selectedMedia
            setSelectedMedia(selectedMedia.filter(m => m.url !== object.url));
        } else {
            // Add media to selectedMedia
            setSelectedMedia([...selectedMedia, object]);
        }

        if(onSelect){
            onSelect(object);
        }

        if(!multiple){
            onClose?.();
        }
    }

    const filteredData = useMemo(() => {
        if(!searchValue) return data?.data?.filter(media => !relevantMedias.includes(media));
        return data?.data?.filter(media => media.description?.toLowerCase().includes(searchValue.toLowerCase()));
    }, [data, relevantMedias, searchValue]);

    if(isLoading) return <div className="flex items-center justify-center h-full p-24 w-full">
        <Loader/>
    </div>

    return (
        <div className={"flex flex-col gap-4 h-full w-full"}>
            {data?.data&&data?.data?.length>0&&
            <div className="flex items-center justify-between gap-4">
                <div className="flex-grow">
                    {panel==='list' && <Input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search"
                        className="w-full"
                    />}
                </div>
                <div className="flex gap-2">
                    <Button
                        onClick={() => setPanel('list')}
                        variant={panel==='list' ? undefined : 'outline'}
                    >
                        <LayoutGrid className="size-6"/>
                    </Button>
                    <Button
                        onClick={() => setPanel('upload')}
                        variant={panel==='upload' ? undefined : 'outline'}
                    >
                        <Upload className="size-6"/>
                    </Button>
                </div>
            </div>
            }

            {panel==='upload' && <MediaUploader
            onUploaded={() => setPanel('list')}
            />}

            {panel==='list' && <ScrollArea className="h-[80vh]">
                <div className="grid gap-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
                    {!searchValue&&(relevantMedias.length>0)&&relevantMedias.map(media => (
                        <MediaElement
                            key={media.uuid}
                            media={media}
                            onDelete={handleDelete}
                            onSelect={onSelect ? handleSelect : undefined}
                            className="border-3 border-primary"
                        />
                    ))}

                    {filteredData?.map(media => (
                        <MediaElement
                            key={media.uuid}
                            media={media}
                            onDelete={handleDelete}
                            onSelect={onSelect ? handleSelect : undefined}
                        />
                    ))}

                    {!searchValue ? null : publicMedias?.map((media, index) => (
                        <PublicMediaElement
                            key={media.url}
                            media={media}
                            onSelect={onSelect ? handlePublicSelect : undefined}
                        />
                    ))}
                </div>
                </ScrollArea>}
            </div>
                )
            }

            export default MediaLibrary;