import {useEffect, useState} from "react";
import constants from "@/config/constants";

const useDarkMode = () => {
    const hasStoredDarkMode = localStorage.getItem(constants.DARK_MODE) !== null;

    const storedDarkMode = localStorage.getItem(constants.DARK_MODE) === "true";
    const browserDarkMode = false // We force to false for the moment. Init value was: window.matchMedia("(prefers-color-scheme: dark)").matches;

    const [isDarkMode, setIsDarkMode] = useState<boolean>(hasStoredDarkMode ? storedDarkMode : browserDarkMode);

    const _setModeClass = (isDarkMode: boolean) => {
        if (isDarkMode) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }

    useEffect(() => {
        _setModeClass(isDarkMode);

        localStorage.setItem(constants.DARK_MODE, isDarkMode.toString());
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    }

    return {isDarkMode, toggleDarkMode, setIsDarkMode};
}

export default useDarkMode;