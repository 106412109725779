import EmojiPicker, {EmojiStyle, Theme} from "emoji-picker-react";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {create} from 'zustand'
import useAnyModel from "@/hooks/useAnyModel";
import useDarkMode from "@/hooks/useDarkMode";
import {cn} from "@/lib/utils";

export type ObjectIconProps = {
    className?: string
    defaultIcon?: ReactNode
    model: string
    item?: any
    name: string
    params?: Record<string, any>
    disabled?: boolean
}

const useIconPickerStore = create((set) => ({
    isOpen: false,
    open: () => set({isOpen: true, openedOnce: true}),
    close: () => set({isOpen: false}),
    handleIconChange: (data: any) => {
    },
    setHandleIconChange: (handleIconChange: any) => set({handleIconChange}),
    openedOnce: false,
    pickerPosition: {top: 0, left: 0},
    setPickerPosition: (pickerPosition: { top: number, left: number }) => set({pickerPosition})
}))

export const IconPicker = () => {
    const {isOpen, close, pickerPosition, openedOnce, handleIconChange} = useIconPickerStore((state) => state) as any;
    const {isDarkMode} = useDarkMode()

    const onEmojiClick = (e: any) => {
        close();
        handleIconChange(e)
    }

    const onClickOutside = useCallback((e: any) => {
        if (e.target.closest('.EmojiPickerReact')) return;
        close()
    }, [close]);

    useEffect(() => {
        document.addEventListener('click', onClickOutside)

        return () => {
            document.removeEventListener('click', onClickOutside)
        }
    }, [onClickOutside])

    if (!openedOnce) return null;

    return (
        <EmojiPicker
            open={isOpen}
            theme={isDarkMode ? Theme.DARK : Theme.LIGHT}
            emojiStyle={EmojiStyle.NATIVE}
            onEmojiClick={onEmojiClick}
            style={{
                position: 'fixed',
                top: pickerPosition.top,
                left: pickerPosition.left,
                zIndex: 100000000
            }}
            customEmojis={[
                {
                    names: [
                        'default',
                        'no icon'
                    ],
                    imgUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
                    id: 'app-default',
                }
            ]}
        />
    )
}

const ObjectIcon = ({className, defaultIcon, model, item, name, params,disabled}: ObjectIconProps) => {
    const [icon, setIcon] = useState<ReactNode | null>(null)
    const {isOpen, open, close, setHandleIconChange, setPickerPosition} = useIconPickerStore((state) => state) as any;

    const {patch} = useAnyModel(model, item);

    const handleClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        if (isOpen) {
            close();
        }

        setTimeout(() => {
            setHandleIconChange((data: any) => {
                // if (!data.isCustom) {
                const emoji = data.emoji === 'app-default'
                    ? null
                    : data.emoji

                patch(name, emoji, params)
                setIcon(emoji)
                // }
            });

            setPickerPosition({top: e.clientY, left: e.clientX})

            open()
        }, isOpen ? 100 : 1);
    }

    useEffect(() => {
        if (item && item[name]) {
            setIcon(item[name])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <button
            className={cn([
                'size-6',
                'inline-flex',
                'justify-center',
                'items-center',
                'rounded-[1px]',
                'hover:bg-black/5',
                'dark:hover:bg-white/5'
            ], {
                'pointer-events-none': disabled
            },className)}
            onClick={handleClick}
        >
            {icon && <span className="text-lg">{icon}</span>}

            {!icon && <span className="block p-1 [&>*]:w-5">
                {defaultIcon ?? <span className="opacity-20">{'•'}</span>}
            </span>}
        </button>
    )
}

export default ObjectIcon