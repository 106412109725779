import {Media, PublicMedia} from "@/api/media.types";
import {cn} from "@/lib/utils";
import ImageProxy from "@/modules/medias/components/ImageProxy";
import {Plus, X} from "lucide-react";
import React, {useState} from "react";
import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import MediaLibrary from "@/modules/medias/components/MediaLibrary";

type SelectedMediaListProps = {
    medias: (Media|PublicMedia)[];
    setMedias: (medias: (Media|PublicMedia)[]) => void;
    className?: string;
    canAdd?: boolean;
    canAddMultiple?: boolean;
    addRelevancySearch?: string;
}

const ObjectMedias = ({medias, setMedias,className, canAdd, canAddMultiple, addRelevancySearch}: SelectedMediaListProps) => {
    const [mediaLibraryIsOpen, setMediaLibraryIsOpen] = useState(false);
    const handleMediaSelect = (media: any) => {
        setMediaLibraryIsOpen(false);
        setMedias([media]);
    }

    return (
        <>
            <div className={cn("pt-0 h-20 flex-shrink-0 flex gap-2", className)}>
            {medias.map(media => (
                <div key={(media as Media).uuid ?? (media as PublicMedia).url}
                     className="w-auto h-full aspect-square relative">
                    {
                        (media as Media).uuid ?
                            <ImageProxy
                                uuid={(media as Media).uuid}
                                className="object-cover rounded-md absolute top-0 left-0 z-10 w-full h-full"
                                width={300}
                                fit="ratio"
                            /> : <img
                                src={(media as PublicMedia).thumbnail}
                                className="object-cover rounded-md absolute top-0 left-0 z-10 w-full h-full"
                                alt={(media as PublicMedia).url}
                            />
                    }

                    <div
                        className="absolute top-2 right-2 z-20 bg-white size-5 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={() => setMedias(medias.filter(m => m !== media))}>
                        <X className="text-black size-3"/>
                    </div>
                </div>
            ))}

            {canAdd && (canAddMultiple || medias.length === 0) && <button className="w-auto h-full aspect-square relative border-2 border-dashed border-gray-300 dark:border-gray-700 rounded-md flex items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMediaLibraryIsOpen(true)
            }}>
                <Plus className="text-gray-500 size-6"/>
            </button>}
        </div>

        <Dialog
            open={mediaLibraryIsOpen}
            onOpenChange={setMediaLibraryIsOpen}
        >
            <DialogContent
                className="w-[92%] max-w-[1300px] max-h-[92vh]"
            >
                <DialogHeader>
                    <DialogTitle>
                        Add a media
                    </DialogTitle>
                    <DialogDescription>
                        <MediaLibrary
                            onClose={() => setMediaLibraryIsOpen(false)}
                            onSelect={handleMediaSelect}
                            multiple={canAddMultiple}
                            relevancySearch={addRelevancySearch}
                        />
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    </>
    )
}

export default ObjectMedias;