import {cn} from "@/lib/utils";

type LogoProps = {
    className?: string
}

const Logo = ({className}: LogoProps) => {
    return (
        <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn(className)}>
            <path
                d="M12.5343 27.4902C6.2021 27.4902 1.0314 22.3413 1.00034 16.006V15.978C0.975499 12.0961 2.30466 8.53097 3.63072 6.71113C4.41642 5.6242 5.1928 5.12731 6.00335 5.19253C7.04059 5.25464 7.55922 5.89127 7.84492 6.61486C8.32939 5.76084 8.9536 4.81055 9.73619 3.90373C11.7858 1.53422 14.3479 0.431755 15.823 1.28888C17.1957 2.07147 17.177 3.68635 17.1677 4.75465C17.1677 4.9534 17.1677 5.15216 17.1739 5.3447C17.2112 6.49374 17.5124 7.69248 17.8447 8.68935L17.8571 8.46575C17.8789 7.85707 17.9255 6.55585 19.2453 6.24841C19.3695 6.22046 19.5 6.20493 19.6273 6.20493C20.2391 6.20493 20.8384 6.52791 21.4005 7.16764C22.8912 8.87568 24.0154 12.5495 24.0744 15.9004V15.9283C24.0744 22.3133 18.8975 27.4933 12.5343 27.4933V27.4902ZM5.87602 6.43163C5.54373 6.43163 5.09964 6.79498 4.63381 7.44093C3.46613 9.04649 2.21771 12.3911 2.23945 15.9439V15.9873C2.26429 21.6456 6.88221 26.248 12.5343 26.248C18.1863 26.248 22.8322 21.627 22.8322 15.9501V15.9345C22.7701 12.4595 21.5682 9.25145 20.4658 7.9875C20.1118 7.58689 19.767 7.40366 19.5248 7.45956C19.2112 7.53099 19.1242 7.75148 19.0962 8.52786L19.0807 8.80426L18.677 13.4594L17.6926 11.5713C17.6242 11.4409 16.0249 8.34774 15.9286 5.38197C15.9224 5.1739 15.9224 4.96272 15.9224 4.74844C15.9348 3.63666 15.8882 2.7578 15.2019 2.3665C14.4007 1.90378 12.4442 2.67395 10.6741 4.72049C9.4598 6.1304 8.64926 7.65832 8.18343 8.69246L7.27661 10.7079L7.00022 8.51544C6.99401 8.47818 6.9878 8.44091 6.98469 8.40364C6.97848 8.36017 6.96917 8.31669 6.96606 8.27321C6.76731 7.01547 6.57787 6.47822 5.91329 6.43785C5.90086 6.43785 5.88844 6.43785 5.87602 6.43785V6.43163Z"
                fill="currentColor" stroke="currentColor" strokeWidth="0.621105" strokeMiterlimit="10"/>
            <path
                d="M12.5373 23.7233C10.2733 23.7233 8.23921 22.3755 7.35724 20.2916C7.2237 19.9749 7.37277 19.6115 7.68642 19.478C8.00319 19.3445 8.36653 19.4935 8.50007 19.8072C9.18639 21.4314 10.7702 22.481 12.5342 22.481C14.2981 22.481 15.8353 21.4593 16.5372 19.8817C16.6769 19.5681 17.0434 19.4283 17.357 19.5681C17.6707 19.7078 17.8104 20.0743 17.6707 20.3879C16.767 22.4158 14.7515 23.7264 12.5342 23.7264L12.5373 23.7233Z"
                fill="currentColor" stroke="currentColor" strokeWidth="0.621105" strokeMiterlimit="10"/>
        </svg>

    )
}

export default Logo