import {useGetUserSubscriptionsQuery} from "@/api/user";
import {useMemo} from "react";
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@/components/ui/card";
import {Button} from "@/components/ui/button";
import SubscriptionPlans from "@/modules/subscription/SubscriptionPlans";
import useUser from "@/hooks/useUser";

const UserSubscriptionPanel = () => {
    const {user} = useUser();
    const {data, isLoading} = useGetUserSubscriptionsQuery();

    const currentSubscription = useMemo(()=>{
        const activeSubscription =  data?.find((subscription) =>{
            return subscription.status === 'active' || subscription.status === 'trialing';
        });

        if(activeSubscription) return activeSubscription;
        return null;
    }, [data]);

    if(isLoading||!user) return null;

    if(!currentSubscription)
    return (
        <div
            className="fixed top-0 left-0 z-50 w-full h-full bg-background/60 backdrop-blur-sm flex items-center justify-center"
        >
            <div className="max-w-[1200px] w-[80%] flex flex-col items-end justify-center gap-4">
                <Card className="w-full rounded-lg bg-primary-300 text-primary-foreground border-none opacity-0 animate-pop-up ease-out-expo delay-200">
                    <CardHeader>
                        <CardTitle>
                            Pricing Plan
                        </CardTitle>
                        <CardDescription>
                            Find the perfect plan to match your business goals and start growing today.
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="py-8">
                        <SubscriptionPlans />
                    </CardContent>
                </Card>
                <Card className="rounded-lg bg-tertiary text-tertiary-foreground border-none opacity-0 animate-pop-up ease-out-expo delay-500"><CardHeader>
                    <CardTitle>
                        Need help?
                    </CardTitle>
                    <CardDescription>
                        Contact us and we'll be happy to help you.
                    </CardDescription>
                </CardHeader>
                    <CardContent className="flex justify-end">
                        <Button asChild variant="white">
                            <a href="https://linkedin.com/in/thomas-durand-9194857a" target="_blank" rel="noreferrer">
                                Contact us
                            </a>
                        </Button>
                    </CardContent>
                </Card>
            </div>
        </div>
    )

    return null;
}

export default UserSubscriptionPanel;