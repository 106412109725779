import {useMemo} from "react";

type ImageProxyProps = {
    uuid: string;
    width?: number;
    height?: number;
    fit?: 'contain' | 'cover' | 'fill' | 'ratio'
    format?: 'jpeg' | 'png' | 'webp';
    alt?: string;
    className?: string;
}

const ImageProxy = ({className,uuid, width, height, fit, format,alt}: ImageProxyProps) => {
    const baseUrl = process.env.REACT_APP_API_HOST;
    const queryParams = useMemo(() => ({
        width: width,
        height: height,
        fit: fit,
        format: format
    } ), [width, height, fit, format]);

    const queryString = useMemo(() => {
        let queryString = '';
        for (const [key, value] of Object.entries(queryParams)) {
            if (value) {
                queryString += `${key}=${value}&`;
            }
        }
        return queryString;
    }, [queryParams]);

    return <img
        src={`${baseUrl}/media/proxy/${uuid}?${queryString}`}
        alt={alt}
        loading="lazy"
        className={className}
    />
}

export default ImageProxy;