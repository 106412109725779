import {PropsWithChildren} from "react";
import {FlameKindling} from "lucide-react";

type CreateFirstProps = {
    title?: string
    description?: string
}

const CreateFirst = ({title, description, children}: PropsWithChildren<CreateFirstProps>) => {
    return (
        <div className="flex flex-col items-center justify-center space-y-2 pt-24">
            <FlameKindling className="size-24 opacity-30 mb-8"/>

            {title && <h2 className="text-2xl font-semibold font-display">{title}</h2>}
            {description && <p className="opacity-50 text-sm">{description}</p>}
            {children && <div className={"flex gap-4 pt-8"}>{children}</div>}
        </div>
    )
}

export default CreateFirst;