import {api} from "@/api/api";
import {Media, MediaPostQuery, PublicMedia} from "@/api/media.types";
import {ObjectQuery, PaginatedQuery, PaginatedResponse} from "@/api/api.types";

export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMedias: builder.query<PaginatedResponse<Media>, PaginatedQuery>({
            query: () => `medias`,
            providesTags: ["Medias"],
        }),
        getMedia: builder.query<Media, ObjectQuery>({
            query: ({uuid}) => `medias/${uuid}`,
            providesTags: (result, error, {uuid}) => [{type: "Medias", id: uuid}],
        }),
        uploadMedia: builder.mutation<Media, MediaPostQuery & {
            base64: string
        }>({
            query: (media) => ({
                url: `medias`,
                method: "POST",
                body: media,
            }),
            invalidatesTags: ["Medias"],
        }),
        deleteMedia: builder.mutation<void, ObjectQuery>({
            query: ({uuid}) => ({
                url: `medias/${uuid}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Medias"],
        }),
        patchMedia: builder.mutation<Media, ObjectQuery & MediaPostQuery>({
            query: ({uuid, ...media}) => ({
                url: `medias/${uuid}`,
                method: "PATCH",
                body: media,
            }),
            invalidatesTags: ["Medias"],
        }),
        publicSearch: builder.query<PublicMedia[], {query: string}>({
            query: ({query}) => ({
                url: `media/public-search?query=${query}`,
                method: "GET",
            }),
            providesTags: ["Medias"],
        }),
    }),
});

export const {
    useGetMediasQuery,
    useUploadMediaMutation,
    useDeleteMediaMutation,
    useGetMediaQuery,
    usePatchMediaMutation,
    usePublicSearchQuery,
} = userApi;
