const ComingSoon = () => {
    return (
        <div className="w-full h-[200px] flex items-center justify-center">
            <div className="text-center text-sm text-foreground/50">
                More widgets and features coming soon
            </div>
        </div>
    )
}

export default ComingSoon;