import {Helmet} from "react-helmet";
import React, {useEffect} from "react";
import {
    useGetListPipelineQuery,
    useGetListQuery,
    usePatchListPipelineMutation,
    useSetListPipelineStagesOrderMutation
} from "@/api/list";
import {useParams} from "react-router";
import StageContainer from "./pipelines/components/StageContainer";
import {ReactSortable} from "react-sortablejs";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";
import {Cog} from "lucide-react";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger} from "@/components/ui/sheet";
import {Checkbox} from "@/components/ui/checkbox";
import {clsx} from "clsx";
import {Slider} from "@/components/ui/slider";

const Pipelines = () => {
    const {t} = useTranslation();

    const {listUuid: listUuidParam} = useParams();

    const {data} = useGetListPipelineQuery({
        uuid: listUuidParam ?? ''
    });

    const [patchPipeline] = usePatchListPipelineMutation();

    const [updateListPipelineStagesOrder] = useSetListPipelineStagesOrderMutation();

    const sortableStages = (data?.stages ?? []).map((stage) => ({
        ...stage,
        id: stage.uuid
    }));

    const stagesOrder = (data?.stages ?? []).map((stage) => stage.uuid);

    const {data: list} = useGetListQuery({
        uuid: listUuidParam ?? ''
    });
    const setStagesOrder = (newStages: typeof sortableStages) => {
        const newStagesOrder = newStages.map((stage) => stage.uuid);
        if (newStagesOrder.join(',') !== stagesOrder.join(',')) {
            updateListPipelineStagesOrder({
                uuid: listUuidParam ?? '',
                stageUuids: newStagesOrder
            });
        }
    }

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('pipelines.page_title'));
    }, [setCrumbs, setTitle, t]);

    return (
        <>
            <Helmet>
                <title>{t('pipelines.page_title')}</title>
            </Helmet>

            <div
                className="p-4">
                <div
                    className="flex opacity-0 justify-end gap-4"
                >
                    <Sheet>
                        <SheetTrigger>
                            <Cog/>
                        </SheetTrigger>
                        <SheetContent>
                            <SheetHeader>
                                <SheetTitle>
                                    {t('pipelines.settings.title')}
                                </SheetTitle>
                                <SheetDescription>
                                    {t('pipelines.settings.description')}
                                </SheetDescription>
                            </SheetHeader>

                            <div className="mt-8">
                                <div className="items-top flex space-x-2">
                                    <Checkbox id="auto_move_enabled"
                                              checked={!!data?.autoMoveEnabled}
                                              onCheckedChange={(value) => {
                                                  patchPipeline({
                                                      uuid: listUuidParam ?? '',
                                                      autoMoveEnabled: !!value
                                                  });
                                              }}
                                    />
                                    <div className="grid gap-1.5 leading-none mt-4">
                                        <label
                                            htmlFor="auto_move_enabled"
                                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                        >
                                            {t('pipelines.settings.auto_move_enabled')}
                                        </label>
                                        <p className="text-sm text-muted-foreground">
                                            {t('pipelines.settings.auto_move_enabled_description')}
                                        </p>
                                    </div>
                                </div>

                                {data?.autoMoveEnabled && <div
                                    className={clsx([
                                        "space-y-4"
                                    ])}
                                >
                                    {data.stages.map((stage) => (
                                        <div key={stage.uuid}>
                                            {stage.name}

                                            <Slider
                                                defaultValue={[stage.scoreTrigger ?? 0]}
                                                max={100}
                                                step={1}
                                                onValueChange={(value) => {
                                                    console.log(value)
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>}
                            </div>
                        </SheetContent>
                    </Sheet>
                </div>


                {list &&
                    <div className="mt-4">
                        <ReactSortable list={sortableStages} setList={setStagesOrder} className="flex">
                            {sortableStages.map((stage) => (
                                <StageContainer list={list} key={stage.uuid} stage={stage}/>
                            ))}
                        </ReactSortable>
                    </div>
                }

            </div>
        </>
    )
}

export default Pipelines