import {useEffect} from "react";
import {Button} from "@/components/ui/button";

const extensionUrl = 'https://chromewebstore.google.com/detail/warmr/achcinfieogfidhjekdbbmapmffifchl';

const Extension = () => {
    useEffect(() => {
        // Redirect to the extension page
        setTimeout(() => {

            window.location.href = extensionUrl;
        }, 1000);

    }, []);

    return <div className="flex flex-col items-center justify-center h-full">
        <Button asChild ><a href={extensionUrl} target="_blank" rel="noreferrer" >
 Download the extension
        </a>
        </Button>
    </div>
}

export default Extension;