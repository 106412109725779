import {Contact} from "@/api/contact.types";
import {twc} from "react-twc";
import {EditableTextValue} from "@/components/data/EditableTextValue";
import {useTranslation} from "react-i18next";
import {Separator} from "@/components/ui/separator";
import ContactListPipeline from "@/modules/contacts/components/ContactListPipeline";

type ContactInformationProps = {
    contact: Contact
}

const ContactInformationContainer = twc.div`flex gap-8 flex-wrap mt-8 w-full max-w-[700px]`
const ContactInformationField = twc.div`w-[calc(50%-1rem)]`
const ContactInformationLabel = twc.div`text-sm font-bold opacity-50`

const ContactInformation = ({contact}: ContactInformationProps) => {
    const {t} = useTranslation()

    const fields = [
        'first_name',
        'last_name',
        'email',
        'phone',
        'source'
    ];

    return (
        <ContactInformationContainer>
            {fields.map(field => (
                <ContactInformationField key={field}>
                    <ContactInformationLabel>
                        {t('common.fields.' + field)}
                    </ContactInformationLabel>
                    <EditableTextValue name={field} item={contact} model={"contact"}/>
                </ContactInformationField>
            ))}

            <Separator />

            <ContactListPipeline
                contact={contact}
            />

        </ContactInformationContainer>
    )
}

export default ContactInformation