import {Media} from "@/api/media.types";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";
import ImageProxy from "@/modules/medias/components/ImageProxy";
import {Button} from "@/components/ui/button";
import {Ellipsis, FileText} from "lucide-react";
import {cn} from "@/lib/utils";

type MediaElementProps = {
    media: Media;
    onDelete?: (media: Media) => void;
    onSelect?: (media: Media) => void;
    className?: string;
}

const MediaElement = ({media, onDelete, onSelect,className}: MediaElementProps) => {
    return (
        <div key={media.uuid} className={cn("flex gap-4 items-center flex-col relative aspect-square group bg-muted",className)}>
            {media.type.includes('image') ?
            <ImageProxy
                uuid={media.uuid}
                className="object-cover absolute top-0 left-0 h-full w-full"
                width={300}
                fit="ratio"
            /> :
                <div className="w-full h-full flex justify-center items-center">
                    {media.type.includes('pdf') &&
                        <FileText />
                    }
                </div>
            }

            <div className="absolute top-0 left-0 w-full h-full z-20 bg-black/50 text-white flex flex-col justify-between items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 " >
                <div className="line-clamp-2 p-4">&nbsp;</div>

                {onSelect&&
                <Button  onClick={() => onSelect?.(media)} className="bg-white text-black">
                    Select
                </Button>}

                <div className="text-xs p-4">
                    <div className="line-clamp-2">{media.description??media.name??media.type}</div>
                </div>
            </div>

            <div className="flex flex-col absolute top-2 right-0 z-20">
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="link" className="!outline-none !ring-0 !ring-offset-0">
                            <Ellipsis className="size-8 text-white filter-shadow"/>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuItem onClick={() => onDelete?.(media)}>
                            Delete
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
    )
}

export default MediaElement;