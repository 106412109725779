import {Notification} from "./notification.types";
import {api} from "@/api/api";
import {PaginatedQuery, PaginatedResponse} from "@/api/api.types";

export const notificationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query<PaginatedResponse<Notification>, PaginatedQuery>({
            query: ({page}) => `notifications?page=${page ?? 1}`,
            providesTags: ["Notifications"],
        }),
        patchUserNotification: builder.mutation<void, {
            id: number,
            read?: boolean,
            clicked?: boolean,
        }>({
            query: ({id, ...rest}) => ({
                url: `notifications/${id}`,
                method: "PATCH",
                body: rest
            }),
            invalidatesTags: (result, error, {id}) => ["Notifications"],
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    usePatchUserNotificationMutation,
} = notificationApi;
