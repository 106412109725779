import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@/components/ui/card";
import {Skeleton} from "@/components/ui/skeleton";
import {UserAvailablePlan} from "@/api/user.types";
import {Button} from "@/components/ui/button";
import {cn} from "@/lib/utils";
import {useSubscribeUserMutation} from "@/api/user";

type PlanCardProps = {
    loading?: boolean;
    plan?: UserAvailablePlan;
    recurringPlanType?: 'month' | 'year';
}

const PlanCard = ({plan,loading,recurringPlanType}: PlanCardProps) => {
    const [subscribe, {isLoading:subscriptionIsLoading}] = useSubscribeUserMutation();

    if(loading||!plan){
        return (
            <Card
                className="w-[300px] min-h-[400px] shadow-none h-full"
            >
                <CardHeader>
                    <CardTitle>
                        <Skeleton className="h-6 w-10"/>
                    </CardTitle>
                    <CardDescription>
                        <Skeleton className="h-4 w-20"/>
                    </CardDescription>
                </CardHeader>

                <CardContent>
                    <ul className="flex flex-col gap-2">
                        <li>
                            <Skeleton className="h-4 w-20"/>
                        </li>
                        <li>
                            <Skeleton className="h-4 w-20"/>
                        </li>
                        <li>
                            <Skeleton className="h-4 w-20"/>
                        </li>
                    </ul>
                </CardContent>
            </Card>
        )
    }

    return (
        <Card
            key={plan.internal_product_id}
            className={cn("w-[300px] min-h-[400px] shadow-none h-full flex flex-col", {
                'pointer-events-none relative overflow-hidden opacity-80 text-foreground/50': plan.soon
            })}
        >
            <CardHeader>
                <CardTitle>{plan.name}</CardTitle>
                <CardDescription>{plan.description}</CardDescription>
            </CardHeader>

            <CardContent className="flex-grow flex flex-col gap-2">
                <ul className="flex flex-col gap-2 text-sm">
                    {plan.features?.map((feature) => (
                        <li key={feature}>{feature}</li>
                    ))}
                </ul>

                <div className="flex-grow">
                    &nbsp;
                </div>

                {plan.active && plan.prices.filter((price) => price.active && price.interval === recurringPlanType).map((price) => (
                    <div key={price.product} className="flex flex-col items-end justify-end mt-4 text-xl">
                        {price.default_price&&<p
                            className="text-sm line-through"
                        >
                            {price.default_price / 100} €
                        </p>}

                        <p className="font-bold">
                            {price.unit_amount / 100} € / {price.interval}
                        </p>
                    </div>
                ))}

                <Button className={"w-full"} disabled={!plan.active||subscriptionIsLoading}
                onClick={(e:any)=>{
                    e.preventDefault();
                    e.stopPropagation();

                    subscribe({
                        internal_product_id: plan.internal_product_id,
                        interval: recurringPlanType
                    }).then((response) => {
                        if(response.data?.redirectUrl) {
                            window.location.href = response.data.redirectUrl;
                        }
                    });
                }}
                >
                    Start a 7-day free trial
                </Button>
            </CardContent>

            {plan.soon && <div
                className="absolute top-12 -right-12 bg-primary text-primary-foreground p-2 text-sm uppercase w-60 text-center rotate-45">
                Coming soon
            </div>}
        </Card>
    )
}

export default PlanCard;