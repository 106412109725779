import {cn} from "@/lib/utils";
import Logo from "./Logo";

const Loader = ({className}: {
    className?: string
}) => {
    return (
        <div className={cn('w-full h-full flex items-center justify-center', className)}>
            <Logo className="size-8 opacity-10 animate-pulse"/>
        </div>
    )
}

export default Loader