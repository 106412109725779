import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";
import {useDeleteResourceMutation, useGetResourcesQuery} from "@/api/resource";
import {ExternalLinkIcon, Recycle} from "lucide-react";
import {Button} from "@/components/ui/button";
import {Bin} from "iconoir-react";
import {Link} from "react-router-dom";

const Resources = () => {
    const {t} = useTranslation();

    const {data} = useGetResourcesQuery({
        page: 1
    });

    const [deleteResource, {
        isLoading: isDeleting
    }] = useDeleteResourceMutation();

    const handleDelete = (uuid: string) => {
        if (isDeleting) return;

        deleteResource({uuid})
    }

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('resources.page_title'));
    }, [setCrumbs, setTitle, t]);


    return (
        <>
            <Helmet>
                <title>
                    {t('resources.page_title')}
                </title>
            </Helmet>

            <div
                className="p-4 flex gap-4 flex-wrap"
            >
                {data?.data?.map((resource, index) => {
                    return (
                        <div
                            key={index}
                            className="w-64  border bg-muted/50 flex flex-col justify-between"
                        >
                            <div className="p-4">
                                <p
                                    className="text-sm opacity-50"
                                >
                                    {t('resources.types.' + resource.type)}
                                </p>

                                <h2
                                    className="text-lg font-semibold"
                                >
                                    {resource.display_name}
                                </h2>

                                {resource.summary &&
                                    <p
                                        className="text-sm mt-2"
                                    >
                                        {resource.summary}
                                    </p>
                                }
                            </div>

                            <div
                                className="flex justify-end"
                            >
                                {resource.url?.includes('http') && (
                                    <Button asChild
                                            variant="ghost"
                                    >
                                        <a
                                            href={resource.url}
                                            target="_blank"
                                            rel="noreferrer">
                                            <ExternalLinkIcon
                                                className="size-5"/>
                                        </a>
                                    </Button>
                                )}

                                <Button
                                    onClick={() => handleDelete(resource.uuid)}

                                    variant="ghost"
                                >
                                    <Bin/>
                                </Button>

                                <Button
                                    variant="ghost"
                                    asChild
                                >
                                    <Link to={`/feed/writer?magicPostInit=${encodeURIComponent(resource.value ?? resource.summary ?? resource.display_name ?? '')}`}>
                                        <Recycle/>
                                    </Link>
                                </Button>

                            </div>
                        </div>
                    )
                })}
            </div>
        </>

    )
}

export default Resources